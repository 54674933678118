.property {
    #propertyHeader {
        .aboutUsHeaderBg {
            min-height: 60vh;
            background-image: url(../img/property/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 10;

            @media screen and (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/property/headerBgMobile.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media screen and (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/property/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }

        .internationalSchoolHeader {
            min-height: 60vh;
            background-image: url(../img/internationalSchool/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 10;

            @media screen and (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/internationalSchool/headerBgMobile.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media screen and (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/internationalSchool/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }


        .headerTitle {
            text-align: center;

            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.6vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 991px) {
                    font-size: 3rem;
                }

                @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .propertyContent {
        padding: 15vh 0 25vh 0;

        @media screen and (max-width: 991px) {
            padding: 10vh 0;
        }

        img {
            border-radius: 4vw;

            @media screen and (max-width: 991px) {
                border-radius: 40px;
            }
        }

        .propertyNameTitle {
            color: #1A1D57;
            font-family: 'Microsoft YaHei Bold';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }
        }

        .propertyLocation {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 14px;
            }
        }

        .propertyOverlayLayer {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1s;
        }

        .propertyOverlayLayer::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 4vw;
            background: linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%);
            mix-blend-mode: multiply;
            opacity: 0;

            @media screen and (max-width: 991px) {
                border-radius: 40px;
            }
        }

        .moreDetailsText {
            font-family: 'Microsoft YaHei Bold';
            font-size: 1vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: white;
            isolation: isolate;
            display: inline-block;
            opacity: 0;
            transition: 0;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }

            @media screen and (max-width: 667px) {
                font-size: 17px;
            }
        }

        .schoolSelection {
            box-shadow: 0px 4px 79px 0px rgba(34, 85, 172, 0.12);
            border-radius: 4vw;

            @media screen and (max-width: 991px) {
                border-radius: 40px;
            }
        }

        .propertySelection {
            &:hover {
                .propertyOverlayLayer::before {
                    opacity: 1;
                    transition: 1s;
                }

                .moreDetailsText {
                    opacity: 1;
                }
            }

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1.2/1;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}