.application {
    #applicationHeader {
        .applicationHeaderBg {
            min-height: 60vh;
            background-image: url(../img/application/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 3;

            @media (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/application/headerBg.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/aboutUs/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }


        .headerContentPos {
            width: 100%;
            position: absolute;
            top: 11%;

            @media (max-width: 991px) {
                top: 15%;
            }

            @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                top: 18%;

            }

            @media (max-width: 667px) {
                top: 23%;
            }
        }

        .headerTitle {
            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.8vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: 991px) {
                    font-size: 3rem;
                }

                @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }

        .knowMoreBtnPos {
            margin-top: 5vh;

            @media (max-width: 991px) {
                margin-top: 2rem;
            }

            @media (max-width: 667px) {
                margin-top: 1.5rem;
            }

            @media (max-width: 300px) {
                margin-top: 1rem;
            }
        }

        .knowMoreBtn {
            border-radius: 44px;
            width: fit-content;
            background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.25) 100%);
            border-radius: 3vw;
            box-shadow: 0px 2px 35px 0px #0EB7FF;
            backdrop-filter: blur(3px);
            cursor: pointer;
            padding: 1vw 2.5vw;

            @media (max-width: 991px) {
                padding: 15px 40px;
                border-radius: 50px;
            }

            @media (max-width: 667px) {
                padding: 10px 30px;
                border-radius: 50px;
            }

            @media (max-width: 300px) {
                padding: 8px 20px;
                border-radius: 50px;
            }

            span {
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: 991px) {
                    font-size: 14px;
                }

                @media (max-width: 667px) {
                    font-size: 13px;
                }

                @media (max-width: 300px) {
                    font-size: 10px;
                }
            }

            &:hover {
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                span {
                    background: white;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

        }

        .headerAbsolutePanel1 {
            // position: absolute;
            left: 0;
            margin-top: -15vh;
            position: sticky;
            z-index: 4;

            @media (max-width: 991px) {
                margin-top: -10vh;
            }

            @media (max-width: 667px) {
                margin-top: -10vh;
            }

            .headerPanel1Bg {
                border-radius: 0px 5vw 5vw 0px;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                padding: 0 20vh;
                min-height: 22vw;

                @media (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    padding: 4rem 10vh;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 10vh;
                    min-height: 25vh;
                }

                @media (max-width: 300px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 6vh;
                    min-height: 25vh;
                }
            }

            .headerPanel1Content {

                .headerPanel1Title {
                    color: var(--ffffff, #FFF);
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;

                    @media (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media (max-width: 300px) {
                        font-size: 18px;
                    }
                }

                .headerPanel1Desc {
                    color: #C1E1F2;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 3vh;

                    @media (max-width: 991px) {
                        font-size: 16px;
                    }

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }
            }
        }

        .headerAbsolutePanel2 {
            position: absolute;
            left: 0;
            top: 0vh;
            z-index: 2;

            .headerPanel2Bg {
                border-radius: 5vw 0 0 5vw;
                background: #1A1D57;
                padding: 8vh 0;

                @media (max-width: 991px) {
                    border-radius: 110px 0 0 110px;
                    padding: 3rem 0;
                }

                @media (max-width: 667px) {
                    border-radius: 50px 0 0 40px;
                    padding: 2rem 0;
                }

                .col-12 {
                    @media (max-width: 300px) {
                        padding: 0;
                    }
                }
            }

            .headerPanel2Content {
                padding-top: 20vh;

                @media (max-width: 991px) {
                    padding-top: 32vh;
                }


                @media (max-width: 667px) {
                    padding-top: 28vh;
                }

                .headerIconSize {
                    width: 10vw;
                    aspect-ratio: 2/1;
                    object-fit: contain;

                    @media (max-width: 991px) {
                        aspect-ratio: 1.3/1;
                    }

                    @media (max-width: 667px) {
                        aspect-ratio: 1.2/1;
                    }
                }

                .headerPanel2Desc {
                    color: #C1E1F2;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 2vh;

                    @media (max-width: 991px) {
                        font-size: 16px;
                        padding-bottom: 0vh;
                    }

                    @media (max-width: 667px) {
                        font-size: 14px;
                        padding-bottom: 0vh;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                        padding-bottom: 0vh;
                    }
                }
            }
        }
    }

    .contentIndexText {
        color: #76ACD6;
        font-family: "Microsoft YaHei";
        font-size: 1.1vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width:991px) {
            font-size: 22px;
        }

        @media (max-width:667px) {
            font-size: 16px;
        }
    }

    .sectionDivTitle {
        font-family: 'Microsoft YaHei Bold';
        font-size: 2vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 991px) {
            font-size: 2.6rem;
        }

        @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
            font-size: 1.5rem;
        }

        @media (max-width: 667px) {
            font-size: 1.5rem;
        }

        @media (max-width: 300px) {
            font-size: 1rem;
        }
    }

    .sectionDesc {
        color: #5A5C86;
        font-family: 'Microsoft YaHei';
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        @media (max-width: 667px) {
            font-size: 14px;
        }

        @media (max-width: 300px) {
            font-size: 11px;
        }
    }

    .smallText {
        color: #5A5C86;
        font-family: 'Microsoft YaHei';
        font-size: 0.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 991px) {
            font-size: 14px;
        }

        @media (max-width: 667px) {
            font-size: 12px;
        }

        @media (max-width: 300px) {
            font-size: 11px;
        }
    }

    .headerTitle {
        span {
            font-family: 'Microsoft YaHei Bold';
            font-size: 2.8vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 3rem;
            }

            @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                font-size: 1.7rem;
            }

            @media (max-width: 667px) {
                font-size: 1.7rem;
            }

            @media (max-width: 300px) {
                font-size: 1.2rem;
            }
        }
    }

    .knowMoreSchool {
        background-image: url(../img/application/knowMoreEduBg.webp);
        background-size: cover;
        background-position: bottom;
        position: relative;
        z-index: 1;
        padding: 25vh 0 35vh 0;

        @media (max-width: 991px) {
            padding-top: 40vh;
            background-size: cover;
        }

        @media (max-width: 667px) {
            padding-top: 50vh;
        }
    }

    .englishEducation {
        padding-bottom: 10vh;

        .englishEducationContainer {
            background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
            padding: 4vh 0;
            border-radius: 4vw;
            box-shadow: 10px 4px 79px 0px rgba(34, 85, 172, 0.3);
            margin-top: -15vh;
            position: sticky;
            z-index: 2;
            
            @media (max-width: 991px) {
                border-radius: 3vh;
            }
        }

        .checkGreenBg {
            background: #0EEA3E;
            border-radius: 50%;
            width: 1.4vw;
            height: 1.4vw;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1/1;
            margin-right: 1vw;

            @media (max-width: 991px) {
                width: 2.7vh;
                height: 2.7vh;
                margin-right: 1rem;
            }
        }

        .checkGreenBgMT {
            margin-top: 1vw;

            @media (max-width: 991px) {
                margin-top: 1rem;
            }
        }

        .checkGreenBgMB {
            margin-bottom: 2vw;

            @media (max-width: 991px) {
                margin-bottom: 2rem;
            }
        }
    }

    .english {
        background-image: url(../img/application/englishBg.webp);
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding: 10vh 0 20vh 0;
        margin-top: -10vh;
        z-index: 1;

        @media (max-width: 991px) {
            padding: 15vh 0 15vh 0;
        }

        @media (max-width: 767px) {
            padding: 12vh 0 12vh 0;
        }
    }

    .englishSliderPos {
        margin-top: -10vh;
        position: sticky;
        z-index: 2;
    }

    .american {
        background-image: url(../img/application/americanBg.webp);
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding: 10vh 0 20vh 0;
        margin-top: -15vh;

        @media (max-width: 991px) {
            padding: 15vh 0 15vh 0;
            margin-top: -5vh;
        }

        @media (max-width: 767px) {
            padding: 12vh 0 12vh 0;
        }
    }

    .rightSliderImg {
        width: 20%;

        @media (max-width: 991px) {
            width: 30%;
        }

        @media (max-width: 767px) {
            width: 25%;
        }
    }

    .leftSliderImg {
        width: 20%;

        @media (max-width: 991px) {
            width: 30%;
        }

        @media (max-width: 767px) {
            width: 25%;
        }
    }

    .observation {
        background-image: url(../img/application/observationBg.webp);
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding: 20vh 0 20vh 0;
        margin-top: -15vh;

        @media (max-width: 991px) {
            padding: 15vh 0 15vh 0;
            margin-top: -5vh;
        }

        @media (max-width: 767px) {
            padding: 12vh 0 12vh 0;
        }
    }

    .observationAccordion {
        padding-bottom: 15vh;

        @media (max-width: 991px) {
            padding-top: 2rem;
            padding-bottom: 3rem;
        }

        .accordion__button {
            background-color: transparent !important;
            padding-top: 2vh;
        }

        .accordionTitle {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.2vw;
            line-height: 29px;
            color: #5A5C86;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
            }

            @media (max-width: 300px) {
                font-weight: 500;
                font-size: 15px;
                line-height: 29px;
            }
        }

        .accordionTitle-inactive {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            line-height: 29px;
            color: #5A5C86;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
            }

            @media (max-width: 300px) {
                font-size: 15px;
            }
        }

        .accordionContent {
            font-family: 'Urbanist-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 1vw;
            line-height: 2vw;
            text-align: left;
            color: #5A5C86;

            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 25px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 22px;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                line-height: 15px;
            }
        }

        .accordion__button {
            background: transparent
        }

        .accordion__panel {
            padding-left: 8px;
            padding-top: 4px !important;
        }

        .accordion__button:before {
            display: none;
        }

        .lh-1 {
            line-height: 1;
        }

        .accordion__button::before {
            display: none !important;
        }

        .accordionIcon {
            font-size: 1vw;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        .selectedExpandIcon {
            float: right;
            color: #0EB7FF;
        }

        .nonselectedExpandIcon {
            float: right;
            color: #0EB7FF;
        }

        .borderBottomWhite {
            border-bottom: 1px solid #334170;
            padding-bottom: 1vh;
        }

        .borderTopWhite {
            padding-top: 1vh;
            border-top: 1px solid #334170;
        }

        .borderTopWhite2 {
            padding-top: 1vh;
            border-top: 1px solid #334170;

            @media (max-width: 767px) {
                border: none;
            }
        }
    }

    .applyBg {
        background-image: url(../img/application/applyBg.webp);
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding: 20vh 0 20vh 0;
        margin-top: -15vh;

        @media (max-width: 991px) {
            margin-top: 0vh;
            padding: 15vh 0 15vh 0;
        }

        @media (max-width: 767px) {
            padding: 12vh 0 12vh 0;
        }
    }

    .applyInfoDetails {
        position: sticky;
        z-index: 2;

        @media (max-width: 991px) {
            margin-bottom: 0vh;
        }

        .futureTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 40px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .futureBgDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 1vh;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                padding-top: 0;
            }
        }

        .futureBgPointDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 11px;
            }
        }

        .futureSliderPos {
            position: absolute;
            top: -5vh;
            right: 0;

            @media (max-width: 991px) {
                position: relative;
                top: 0;
            }
        }

        .emptyFutureContainer {
            opacity: 0.5;
            background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
            filter: blur(48.5px);
            width: 100%;
            height: 40vh;
        }

        .futureSliderBg {
            border-radius: 95px 0 0 95px;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 5vh 3vw;

            box-shadow:
                rgba(14, 183, 255, 0.17) 20px -23px 150px 125px inset,
                rgba(14, 183, 255, 0.15) 0px -36px 30px 0px inset,
                rgba(14, 183, 255, 0.1) 0px -79px 40px 0px inset,
                0px 0px 16.5px rgba(35, 99, 195, 0.6),
                rgba(14, 183, 255, 0.09) 0px 16px 8px,
                rgba(14, 183, 255, 0.09) 0px 8px 4px,
                rgba(14, 183, 255, 0.09) 0px 4px 2px,
                rgba(14, 183, 255, 0.06) 0px 2px 1px;
            backdrop-filter: blur(18px);


            @media (max-width: 991px) {
                padding: 3rem 1rem;
                border-radius: 50px;
            }

            .carousel .control-dots {
                text-align: left;
                margin: 0 0;
                left: 5%;

                @media (max-width: 991px) {
                    text-align: left;
                    left: 5%;
                }

                @media (max-width: 300PX) {
                   display: none;
                }
            }

            .carousel .control-dots .dot {
                width: 6vw;
                height: 0.8vh;
                border-radius: 50px;
                position: sticky !important;

                @media (max-width:991px) {
                    width: 70px;
                }

                @media (max-width:667px) {
                    width: 50px;
                }

                @media (max-width: 300px) {
                    width: 40px;
                }
            }

            .carousel .control-dots .dot.selected {
                width: 6vw;
                height: 0.8vh;
                border: 50px;
                background: linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%);

                @media (max-width:991px) {
                    width: 70px;
                }

                @media (max-width:667px) {
                    width: 50px;
                }

                @media (max-width: 300px) {
                    width: 40px;
                }
            }

            .carousel.carousel-slider .control-arrow {
                background: transparent;
                position: absolute;
                bottom: 0;

                opacity: 1;
                border-radius: 50%;
                top: 80%;

                @media (max-width: 991px) {
                    display: none;
                }
            }

            .carousel .control-prev.control-arrow {
                left: auto;
                right: 9% !important;

            }

            .carousel .control-next.control-arrow:before {
                content: url(../img/home/sliderNext.webp) !important;
            }

            .carousel .control-prev.control-arrow:before {
                content: url(../img/home/sliderPrev.webp) !important;
                border-right: 0px;
            }

            .carousel-img {
                position: absolute;
                right: 0;
                bottom: 0%;
                z-index: 999;
                text-align: right;

                &.left {
                    right: 13%;

                    @media (max-width:667px) {
                        right: 25%;
                    }
                }

                &.right {
                    right: 5%;
                }
            }

            .prevImg {
                width: 3vw;

                @media (max-width:991px) {
                    width: 20%;
                }

                @media (max-width:667px) {
                    width: 15%;
                }

                @media (max-width:300px) {
                    width: 30px;
                }
            }

            .nextImg {
                width: 3vw;

                @media (max-width:991px) {
                    width: 20%;
                }

                @media (max-width:667px) {
                    width: 15%;
                }

                @media (max-width:300px) {
                    width: 30px;
                }
            }
        }

        .paymentStepIndex {
            background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
            padding: 0.2vw 0.6vw;
            margin-right: 0.5vw;
            border-radius: 50%;
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 0.7vw;
            font-style: normal;
            font-weight: 800;
            line-height: 1.3vw;

            @media (max-width: 991px) {
                padding: 0.1rem 0.5rem;
                margin-right: 0.5rem;
                font-size: 15px;
                line-height: 25px;
            }

            @media (max-width: 667px) {
                padding: 0.3rem 0.6rem;
                font-size: 14px;
                line-height: normal;
            }

            @media (max-width: 300px) {
                padding: 0.3rem 0.6rem;
                font-size: 11px;
                line-height: normal;
            }
        }

        .futureSliderTitle {
            font-family: 'Microsoft YaHei';
            font-size: 1.7vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 2vh;

            @media (max-width: 991px) {
                font-size: 25px;
                padding-bottom: 1vh;
            }

            @media (max-width: 667px) {
                font-size: 22px;
                padding-bottom: 1vh;
            }

            @media (max-width: 300px) {
                font-size: 20px;
                padding-bottom: 1vh;
            }
        }

        .futurePoint {
            width: 0.4vw;
            height: 2vh;
            border-radius: 11px;
            background: linear-gradient(32deg, #232BC3 0%, #0EB7FF 100%);

            @media (max-width: 991px) {
                width: 8px;
                height: 20px;
            }

            @media (max-width: 667px) {
                width: 8px;
                height: 20px;
            }

            @media (max-width: 300px) {
                width: 8px;
                height: 20px;
            }
        }
    }

    .selectedRollBg {
        background-image: url(../img/application/selectedBg.webp);
        background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding: 30vh 0 20vh 0;
        margin-top: -10vh;

        @media (max-width: 991px) {
            padding: 15vh 0 15vh 0;
        }

        @media (max-width: 767px) {
            padding: 12vh 0 12vh 0;
        }
    }

    .selectedRollDetails {
        margin-top: -10vh;
        margin-bottom: 10vh !important;

        .leftSliderImg {
            width: 40%;

            @media (max-width: 991px) {
                width: 20%;
            }

            @media (max-width: 767px) {
                width: 30%;
            }
        }

        .rightSliderImg {
            width: 40%;

            @media (max-width: 991px) {
                width: 20%;
            }

            @media (max-width: 767px) {
                width: 30%;
            }
        }

        .futureBgDesc {
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-top: 1vh;
            background: linear-gradient(98deg, #2363C3 1.01%, #0EB7FF 39.12%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                padding-top: 0;
            }
        }

        .borderBottomLine {
            border-bottom: 1px solid #BBD6E2;
        }

        .inactiveInfo {
            filter: grayscale(1);
            transition: 1s;
        }

        .activeInfo {
            filter: grayscale(0);
            transition: 1s;
        }
    }

    .selectedRollSlider {
        .text-blue {
            color: #1794E6;
        }

        .customerFeedbackSliderBg {
            border-radius: 110px 0px 0px 110px;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            min-height: 60vh;
            z-index: 0;
            position: relative;

            @media (max-width: 991px) {
                border-radius: 50px 0 0 50px;
                margin-top: -6vh;
                min-height: 42vh;
            }

            @media (max-width: 767px) {
                border-radius: 50px 0 0 50px;
                margin-top: 0;
                min-height: 47vh;
            }

            @media (max-width: 300px) {
                border-radius: 50px 0 0 50px;
                margin-top: 0;
                min-height: 58vh;
            }
        }

        .customerFeedbackSlider {
            width: 70vw;
            left: -30%;
            top: 15%;

            @media (min-width:668px) and (max-width:991px) {
                left: 0%;
                top: -10%;
                width: 100vw;
            }

            @media (max-width:667px) {
                top: 0%;
                left: 0%;
                width: 100%;

            }
        }

        .dot {
            width: 15%;
            height: 14%;
            border-radius: 0 !important;
            background: transparent !important;
            aspect-ratio: 1/1;
            object-fit: contain;
        }

        .selectedRollSliderTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 35px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .selectedRollSliderIndex {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #B6E3FC;

            @media (max-width: 991px) {
                font-size: 35px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .customerFeedbackCard {
            @media (max-width: 767px) {
                margin-top: 2.5vh;
            }

            &.active {
                border-radius: 80px;
                background: #FFF;
                opacity: 1;
                margin-left: 10.8vw;
                z-index: 50;
                position: sticky;
                box-shadow: inset 0px 0px 16.5px rgba(35, 99, 195, 0.6);
                transform: scale(1);
                transition: 1s;
                transform-origin: center;

                @media(max-width:667px) {
                    margin-left: 18vw;

                    border-radius: 34.224px;
                }

            }

            &.inactive {
                border-radius: 80px;
                opacity: 0.5;
                z-index: 0;
                background: linear-gradient(135deg, #FFF 47.27%, rgba(255, 255, 255, 0.62) 100%);
                transform: scale(0.8);
                transition: 1s;
                transform-origin: center;

                @media(max-width:667px) {
                    transform-origin: center;
                    border-radius: 34.224px;
                }
            }
        }

        .rightSliderImg {
            position: absolute;
            left: 20%;
            width: 25%;

            @media (max-width:991px) {
                left: 15%;
            }

            @media (max-width:667px) {
                left: 20%;
            }
        }

        .leftSliderImg {
            width: 25%;
        }

        .cardMinHeight {
            min-height: 40vh;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            @media (max-width:991px) {
                min-height: 36vh;
            }

            @media (max-width:667px) {
                min-height: 40vh;
            }

            @media (max-width:300px) {
                min-height: 52vh;
            }
        }

        .feedback-desc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 15px;
            }

            @media (max-width: 300px) {
                font-size: 13px;
            }
        }

        .feedback-name {
            color: #5A5C86;
            font-family: 'Microsoft YaHei Bold';
            font-size: 1.1vw;
            font-style: normal;
            font-weight: 900;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 15px;
            }

            @media (max-width: 300px) {
                font-size: 13px;
            }
        }
    }

    .videoSlider {
        width: 100%;

        iframe {
            width: 100%;
            height: 40vh;
            border-radius: 1vw;

            @media (max-width: 991px) {
                height: 40vh;
                border-radius: 40px;
            }

            @media (max-width: 667px) {
                height: 30vh;
                border-radius: 40px;
            }
        }

        img {
            border-radius: 60px;
            width: 60%;

            @media (max-width: 667px) {
                border-radius: 30px;
                width: 100%;
            }
        }
    }

    .customerSlider {
        padding-bottom: 15vh;

        .slick-next:before {
            display: none;
        }

        .slick-prev:before {
            display: none;
        }

        .slick-next {
            width: 6vw;
            height: 75px;
            margin-right: 15%;
            top: 50%;
            z-index: 999;

            @media (max-width:991px) {
                margin-right: -15px;
                width: 100px;
            }

            @media (max-width:667px) {
                margin-right: 0;
                width: 80px;
                right: -4%;
            }
        }

        .slick-prev {
            width: 6vw;
            height: 75px;
            margin-left: 15%;
            top: 50%;
            z-index: 999;

            @media (max-width:991px) {
                margin-left: -15px;
                width: 100px;
            }

            @media (max-width:667px) {
                margin-left: 0;
                width: 80px;
                left: -4%;
            }
        }

        @media (min-width:992px) {


            .slick-slide {
                transform: scale(1);
                transition: 1s;
                filter: blur(10px);
                position: relative;
                z-index: 1;

                @media (max-width:991px) {
                    transform: scale(0.8);
                }

                @media (max-width:667px) {
                    transform: scale(0.8);
                }

                .view-btn {
                    display: none;
                }
            }

            .slick-current {
                transform: scale(1);
                transform-origin: bottom;
                transition: 1s;
                filter: blur(0);
                text-align: center;
                z-index: 10;

                @media (max-width:991px) {
                    transform: scale(1);
                }

                .videoSlider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    transition: 1s;
                    transform: scale(1.6);
                }

                iframe {
                    text-align: center;
                    margin: auto;
                }
            }

            .slick-list {
                padding: 13vh 0 !important;
            }
        }
    }

    .applyNow-outer {
        // margin-top: -20vh;
        background: linear-gradient(80deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.2vh 0 1.2vh 0;
        border-radius: 110px;
        position: sticky;
        z-index: 7;

        @media (max-width: 991px) {
            border-radius: 40px;
            padding: 4px 0 4px 0;
        }

        .perfectBg {
            padding: 25vh 0;
            .bg-image{
                background-image: url(../img/home/perfectBg.webp);
                background-size: 100% 100%;
                border-radius: 110px;
            }

            @media (max-width: 991px) {
                padding: 15vh 0;
                border-radius: 60px;
                background-size: cover;
                background-position: center;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                background-size: cover;
                background-position: center;
                padding: 15vh 0;
            }

            .perfect-title {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 2vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media (max-width: 991px) {
                    font-size: 40px;
                }

                @media (max-width: 667px) {
                    font-size: 26px;
                }

                @media (max-width: 300px) {
                    font-size: 20px;
                }
            }

            .perfect-desc {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width:991px) {
                    font-size: 18px;
                }

                @media (max-width:667px) {
                    font-size: 12px;
                }
            }

            .startJourneyBtnPos {
                margin-top: 5vh;

                @media (max-width:991px) {
                    margin-top: 3rem;
                }
            }

            .submit-btn {
                background-color: #fff;
                border-radius: 44px;
                width: fit-content;
                box-shadow: 0px 12px 18px 3px #0EB7FF;
                backdrop-filter: blur(3px);
                border-radius: 3vw;
                cursor: pointer;
                padding: 1.2vw 2.5vw;

                @media (max-width: 991px) {
                    padding: 15px 40px;
                    border-radius: 50px;
                }

                @media (max-width: 667px) {
                    padding: 10px 40px;
                    border-radius: 50px;
                }

                @media (max-width: 300px) {
                    padding: 10px 30px;
                    border-radius: 50px;
                }

                span {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media (max-width:991px) {
                        font-size: 17px;
                    }

                    @media (max-width:667px) {
                        font-size: 12px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                    span {
                        background: white;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }

    .stepperSection {
        padding: 10vh 0 15vh 0;
         
        @media (max-width: 991px) {
            padding: 0vh 0 10vh 0;
        }

        .stepperBottomSection {
            padding: 5vh 0;
        }

        .stepperContentDiv {
            text-align: center;
            width: fit-content;
            margin: auto;
            min-height: 12vh;
            padding: 4vh 8vw;
            white-space: normal;
            background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
            box-shadow: 0px 0px 20px 0px #0EB7FF;
            border-radius: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -20vh !important;
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 1vw !important;
            font-style: normal;
            font-weight: 400;
            transition: 1s;

            @media (max-width: 991px) {
                border-radius: 80px;
                font-size: 16px;
                line-height: normal;
                padding-bottom: 0;
                padding: 2.5vh 2vh;
                min-height: 8vh;
                width: 90%;
                font-size: 1.5vh !important;
                margin-top: -14vh !important;
                margin: auto;
            }

            @media (max-width: 667px) {
                min-height: 10vh;
                font-size: 14px;
                line-height: normal;
                padding-bottom: 0;
                padding: 2.5vh 1vh;
                min-height: 5vh;
                margin-top: -13vh !important;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                line-height: normal;
                padding-bottom: 0;
                background: none;
                box-shadow: none;
            }
        }

        .defaultStepperContent {
            text-align: center;
            width: fit-content;
            margin: auto;
            min-height: 12vh;
            padding: 2vw 8vw;
            white-space: normal;
            background: linear-gradient(180deg, #FFF 21.88%, rgba(255, 255, 255, 0.25) 100%);
            box-shadow: 0px 0px 20px 0px #0EB7FF;
            border-radius: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -20vh !important;
            color: #000;
            font-family: 'Microsoft YaHei';
            font-size: 1vw !important;
            font-style: normal;
            font-weight: 400;
            transition: 1s;

            @media (max-width: 991px) {
                border-radius: 80px;
                font-size: 16px;
                line-height: normal;
                padding-bottom: 0;
                padding: 2.5vh 2vh;
                min-height: 8vh;
                width: 90%;
                font-size: 1.5vh !important;
                margin-top: -14vh !important;
                margin: auto;
            }

            @media (max-width: 667px) {
                min-height: 10vh;
                font-size: 14px;
                line-height: normal;
                padding-bottom: 0;
                padding: 2.5vh 1vh;
                min-height: 5vh;
                margin-top: -13vh !important;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                line-height: normal;
                padding-bottom: 0;
                background: none;
                box-shadow: none;
            }
        }

        .stepperUpperSection {
            background-image: url(../img/application/stepperBg.webp);
            background-size: 100% 100%;
            padding: 35vh 0 10vh 0;
        }

        .futureSliderTitle {
            font-family: 'Microsoft YaHei Bold';
            font-size: 1.7vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 2vh;

            @media (max-width: 991px) {
                font-size: 25px;
                padding-bottom: 1vh;
            }

            @media (max-width: 667px) {
                font-size: 22px;
                padding-bottom: 1vh;
            }

            @media (max-width: 300px) {
                font-size: 20px;
                padding-bottom: 1vh;
            }
        }

        .futureBgDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                padding-top: 0;
            }
        }

        .paymentStepIndex {
            background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
            padding: 0.2vw 0.6vw;
            margin-right: 0.5vw;
            border-radius: 50%;
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 0.7vw;
            font-style: normal;
            font-weight: 800;
            line-height: 1.3vw;

            @media (max-width: 991px) {
                padding: 0.1rem 0.5rem;
                margin-right: 0.5rem;
                font-size: 18px;
                line-height: 25px;
            }

            @media (max-width: 667px) {
                padding: 0.3rem 0.6rem;
                font-size: 14px;
                line-height: normal;
            }

            @media (max-width: 300px) {
                padding: 0.3rem 0.6rem;
                font-size: 11px;
                line-height: normal;
            }
        }

        .rightSliderImg {
            width: 6%;
            position: absolute;
            bottom: 12%;
            right: 7%;

            @media (max-width: 991px) {
                bottom: 16%;
                right: 3%;
                width: 12%;
            }

            @media (max-width: 767px) {
                bottom: -5%;
                right: 3%;
                width: 18%;
            }
        }

        .leftSliderImg {
            width: 6%;
            position: absolute;
            bottom: 12%;
            left: 7%;

            @media (max-width: 991px) {
                bottom: 16%;
                left: 3%;
                width: 12%;
            }

            @media (max-width: 767px) {
                bottom: -5%;
                left: 3%;
                width: 18%;
            }
        }
    }
}