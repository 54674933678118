.longtermPass {
    #longtermPassHeader {
        .aboutUsHeaderBg {
            min-height: 60vh;
            background-image: url(../img/longtermPass/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 10;

            @media screen and (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/longtermPass/headerBgMobile.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media screen and (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/longtermPass/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }


        .headerTitle {
            text-align: center;

            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.6vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 991px) {
                    font-size: 3rem;
                }

                @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }

        .longtermIntroSection {
            background-image: url(../img/longtermPass/longtermIntroBg.webp);
            background-size: 100% 70%;
            background-repeat: no-repeat;
            left: 0;
            margin-top: -20vh;
            padding: 35vh 0 30vh 0;

            @media screen and (max-width: 991px) {
                margin-top: -14vh;
                padding: 20vh 0 5vh 0;
                background-size: 140% 50%;
                background-position: top;
            }

            @media screen and (max-width: 667px) {
                margin-top: -10vh;
                background-size: 220% 50%;
                background-position: top left;
            }

            .headerPanel1Title {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2vw;
                font-style: normal;
                font-weight: 900;
                line-height: 124.2%;
                color: #fff;
                margin-bottom: 3vh;

                @media screen and (max-width: 991px) {
                    font-size: 40px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 25px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 18px;
                }
            }

            .headerPanel1Desc {
                color: #fff;
                font-family: 'Microsoft YaHei';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: 1.3vw;

                @media screen and (max-width: 991px) {
                    font-size: 18px;
                    line-height: normal;
                    padding-bottom: 0;
                }

                @media screen and (max-width: 667px) {
                    font-size: 14px;
                    line-height: normal;
                    padding-bottom: 0;
                }

                @media screen and (max-width: 300px) {
                    font-size: 11px;
                    line-height: normal;
                    padding-bottom: 0;
                }
            }

            .submit-btn {
                border-radius: 44px;
                width: fit-content;
                background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.25) 100%);
                box-shadow: 0px 12px 18px 3px #0EB7FF;
                backdrop-filter: blur(3px);
                border-radius: 3vw;
                cursor: pointer;
                margin: 5vh 0;
                padding: 1vw 2.5vw;

                @media screen and (max-width: 991px) {
                    padding: 15px 40px;
                    border-radius: 50px;
                }

                @media screen and (max-width: 667px) {
                    padding: 10px 40px;
                    border-radius: 50px;
                }

                @media screen and (max-width: 300px) {
                    padding: 10px 30px;
                    border-radius: 50px;
                }

                span {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width:991px) {
                        font-size: 17px;
                    }

                    @media screen and (max-width:667px) {
                        font-size: 12px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                    span {
                        background: white;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .longtermAdvantage {
                padding-top: 0;

                @media screen and (max-width: 667px) {
                    padding-top: 10vh;
                }

                .longtermAdvantageTitle {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 30px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 20px;
                    }
                }

                .longtermAdvantageSliderBg {
                    border-radius: 90px 0px 0px 90px;
                    background: var(--Linear, linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%));
                    min-height: 60vh;
                    margin-top: 10vh;
                    z-index: 0;
                    position: relative;

                    @media (max-width: 991px) {
                        border-radius: 50px 0 0 50px;
                        margin-top: -6vh;
                        min-height: 52vh
                    }

                    @media screen and (max-width:667px) {
                        min-height: 43vh
                    }

                }

                .longtermAdvantageSliderPos {
                    width: 120%;
                    right: 0%;
                    top: 25%;
                    z-index: 10;

                    @media screen and (max-width:991px) {
                        top: 0%;
                        left: 0%;
                        right: 0;
                        margin: auto;
                        width: 100%;
                    }

                    @media screen and (max-width:667px) {
                        top: 0%;
                        left: 0%;
                        right: 0;
                        margin: auto;
                        width: 100%;
                    }
                }

                .longtermAdvantageSliderBox {
                    border-radius: 80px;
                    background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
                    // box-shadow: 0px 4px 79px 0px rgba(34, 85, 172, 0.12), 0px 0px 69px 0px #FFF inset;
                    padding: 0 10vh;
                    min-height: 40vh;

                    @media screen and (max-width:667px) {
                        padding: 0 5vh;
                        min-height: 30vh;
                        border-radius: 40px;
                    }

                    img {
                        width: 10vh;
                        height: 10vh;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }

                    span {
                        color: #41669D;
                        text-align: center;
                        font-family: 'Microsoft YaHei';
                        font-size: 1vw;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;

                        @media screen and (max-width: 991px) {
                            font-size: 16px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 667px) {
                            font-size: 14px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 300px) {
                            font-size: 11px;
                            line-height: normal;
                            padding-bottom: 0;
                        }
                    }
                }

                .slick-list {
                    @media screen and (max-width:667px) {
                        padding-left: 180px !important;
                    }
                }


                .slick-slide {
                    padding: 0 0.5vw;

                    @media screen and (max-width:991px) {
                        padding: 0 8px;
                    }
                }

                .rightSliderImg {
                    position: absolute;
                    left: 25%;
                    width: 30%;

                    @media screen and (max-width:991px) {
                        left: 40%;
                        width: 70%;
                    }

                    @media screen and (max-width:667px) {
                        left: 40%;
                        width: 70%;
                    }
                }

                .leftSliderImg {
                    width: 30%;

                    @media screen and (max-width:991px) {
                        margin-left: -10%;
                        width: 70%;
                    }

                    @media screen and (max-width:667px) {
                        width: 70%;
                        margin-left: -10%;
                    }
                }
            }
        }
    }

    .longtermVideoSection {
        margin-top: -20vh;
        padding-bottom: 20vh;

        @media screen and (max-width:991px) {
            margin-top: -2vh;
            padding-bottom: 2vh;
        }


        .videoSlider {
            width: 100%;

            iframe {
                width: 100%;
                height: 40vh;
                border-radius: 1vw;

                @media screen and (max-width: 991px) {
                    height: 40vh;
                    border-radius: 40px;
                }

                @media screen and (max-width: 667px) {
                    height: 30vh;
                    border-radius: 40px;
                }
            }

            img {
                border-radius: 60px;
                width: 60%;

                @media screen and (max-width: 667px) {
                    border-radius: 30px;
                    width: 100%;
                }
            }
        }

        .longtermVideo {

            .slick-next:before {
                display: none;
            }

            .slick-prev:before {
                display: none;
            }

            .slick-next {
                width: 6vw;
                height: 75px;
                margin-right: 15%;
                top: 50%;
                z-index: 999;

                @media screen and (max-width:991px) {
                    margin-right: -15px;
                    width: 100px;
                }

                @media screen and (max-width:667px) {
                    margin-right: 0;
                    width: 80px;
                    right: -4%;
                }
            }

            .slick-prev {
                width: 6vw;
                height: 75px;
                margin-left: 15%;
                top: 50%;
                z-index: 999;

                @media screen and (max-width:991px) {
                    margin-left: -15px;
                    width: 100px;
                }

                @media screen and (max-width:667px) {
                    margin-left: 0;
                    width: 80px;
                    left: -4%;
                }
            }

            @media screen and (min-width:992px) {


                .slick-slide {
                    transform: scale(1);
                    transition: 1s;
                    filter: blur(10px);
                    position: relative;
                    z-index: 1;

                    @media screen and (max-width:991px) {
                        transform: scale(0.8);
                    }

                    @media screen and (max-width:667px) {
                        transform: scale(0.8);
                    }

                    .view-btn {
                        display: none;
                    }
                }

                .slick-current {
                    transform: scale(1);
                    transform-origin: bottom;
                    transition: 1s;
                    filter: blur(0);
                    text-align: center;
                    z-index: 10;

                    @media screen and (max-width:991px) {
                        transform: scale(1);
                    }

                    .videoSlider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        transition: 1s;
                        transform: scale(1.6);
                    }

                    iframe {
                        text-align: center;
                        margin: auto;
                    }
                }

                .slick-list {
                    padding: 13vh 0 !important;
                }
            }
        }
    }

    .longtermTNC {
        margin-top: 0;
        background: linear-gradient(66deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.4vh 0 0 0;
        border-radius: 110px;
        position: sticky;
        z-index: 1;
        background-position: 30% center;
        background-size: 200% 100%;

        @media screen and (max-width: 991px) {
            padding: 5px 0 0 0;
            border-radius: 40px;
            margin-top: 8vh;
        }

        .longtermTNCInner {
            background-image: url(../img/longtermPass/tncBg.webp);
            background-color: #1A1D57;
            background-size: 100% 100%;
            border-radius: 110px;
            min-height: 100vh;

            @media screen and (max-width: 991px) {
                background-size: cover;
                background-position: bottom center;
                border-radius: 40px;
            }
        }

        .longtermTNCTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #fff;
            padding-top: 5vh;

            @media screen and (max-width: 991px) {
                font-size: 40px;
                padding-top: 0;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
                padding-top: 0;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
                padding-top: 0;
            }
        }

        .longtermTNCTitleText {
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 18px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
            }
        }

        .longtermTNCBox {
            margin-top: 2vh;
            margin-bottom: 0vh;
            position: relative;

            @media screen and (max-width: 991px) {
                margin-top: 1vh;
                margin-bottom: 1vh;
            }

            @media screen and (max-width: 667px) {
                margin: 0;
            }
        }

        .tncHorizontalScroll {
            display: flex;
            justify-content: start;
            align-items: center;
            width: 1400px;
        }

        ::-webkit-scrollbar {
            width: 0px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0);
            border-radius: 50px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent !important;
            border-radius: 50px;
            width: 0px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: transparent !important;
            width: 0px;
        }

        .tncHorizontalScrollX {
            overflow-x: auto;
        }

        .longtermTNCBoxBg {
            border-radius: 30px;
            background-size: 100% 100%;
            min-height: 17vw;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: 1s;

            @media screen and (max-width: 991px) {
                width: 300px;
                background-image: url(../img/home/mm2hItemBg.webp);
                min-height: auto;
                margin-bottom: 3vh;
                padding: 90px 0;
            }

            &:hover {
                background-image: url(../img/home/mm2hItemBg.webp);
            }

            img {
                width: 7vw;
                aspect-ratio: 2/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    width: 180px;
                }

                @media screen and (max-width: 300px) {
                    width: 120px;
                }
            }
        }

        .accordion__button {
            background-color: transparent !important;
            padding-top: 2vh;
        }

        .accordionSectionTitle {
            color: #FFF;
            font-family: 'Microsoft YaHei';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 3vh;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .accordionPosition {
            padding-top: 3vh;
            padding-bottom: 10vh;

            @media screen and (max-width: 991px) {
                padding-top: 2rem;
                padding-bottom: 3rem;
            }
        }

        .accordionTitle {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.2vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 18px;
                line-height: 29px;
            }

            @media screen and (max-width: 300px) {
                font-weight: 500;
                font-size: 15px;
                line-height: 29px;
            }
        }

        .accordionTitle-inactive {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 18px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 18px;
            }

            @media screen and (max-width: 300px) {
                font-size: 15px;
            }
        }

        .accordionContentTitle {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 900;
            font-size: 1vw;
            line-height: 2vw;
            text-align: left;
            color: #C1E1F2;

            @media (max-width: 991px) {
                font-size: 16px;
                line-height: 25px;
            }

            @media (max-width: 677px) {
                font-size: 15px;
                line-height: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                line-height: 15px;
            }
        }

        .accordionContent {
            font-family: 'Urbanist-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9vw;
            line-height: 2vw;
            text-align: left;
            color: #C1E1F2;
            white-space: pre-line;

            @media (max-width: 991px) {
                font-size: 15px;
                line-height: 25px;
            }

            @media (max-width: 677px) {
                font-size: 14px;
                line-height: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                line-height: 15px;
            }
        }


        .accordion__button {
            background: transparent
        }

        .accordion__panel {
            padding-left: 8px;
            padding-top: 4px !important;
        }


        .accordion__button:before {
            display: none;
        }

        .lh-1 {
            line-height: 1;
        }

        .accordion__button::before {
            display: none !important;
        }

        .accordionIcon {
            font-size: 1vw;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        .selectedExpandIcon {
            float: right;
            color: #0EB7FF;

        }

        .nonselectedExpandIcon {

            float: right;
            color: #0EB7FF;

        }


        .borderBottomWhite {
            border-bottom: 1px solid #334170;
            padding-bottom: 1vh;
        }

        .borderTopWhite {
            padding-top: 1vh;
            border-top: 1px solid #334170;
        }
    }

    .roadmap {
        background-image: url(../img/longtermPass/roadmapBg.webp);
        background-size: 100% 100%;
        background-position: bottom;
        padding: 20vh 0 0 0;
        margin-bottom: 15vh;
        margin-top: -15vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 991px) {
            margin-bottom: 0vh;
            margin-top: -15vh;
        }

        .wrapperAll {
            width: 100%;
        }

        .axisWrapper {
            padding: 25vh 0 35vh 0;
            width: 100%;

            @media screen and (max-width: 991px) {
                padding: 15vh 0 40vh 0;
            }
        }

        .notEnoghEvents {
            width: 200%;
        }

        .dot {
            left: 50%;
            width: 0.5vw !important;
            height: 0.5vw !important;
            top: -0.25vw;

            @media screen and (max-width: 991px) {
                width: 11px !important;
                height: 11px !important;
                top: -7px;
            }
        }

        .specificEvent {
            width: 20vw;

            @media screen and (max-width: 991px) {
                width: 300px;
            }

            @media screen and (max-width: 667px) {
                width: 200px;
            }
        }

        .fa-chevron-circle-right {
            top: 63%;
            right: 43%;
            background-image: url(../img/home/right.webp);
            background-size: 100% 100%;
            width: 6vw !important;
            height: 6vw !important;
            color: transparent !important;
            border-color: transparent !important;

            @media screen and (max-width: 991px) {
                top: 50%;
                right: 30%;
                width: 12vh !important;
                height: 12vh !important;
            }

            @media screen and (max-width: 667px) {
                top: 50%;
                right: 22%;
            }
        }

        .fa-chevron-circle-left {
            top: 63%;
            left: 43%;
            background-image: url(../img/home/left.webp);
            background-size: 100% 100%;
            width: 6vw !important;
            height: 6vw !important;
            color: transparent !important;
            border-color: transparent !important;

            @media screen and (max-width: 991px) {
                top: 50%;
                left: 30%;
                width: 12vh !important;
                height: 12vh !important;
            }

            @media screen and (max-width: 667px) {
                top: 50%;
                left: 22%;
            }
        }

        .descriptionEventDetails {
            text-align: center;
            // position: absolute;
            // left: calc(50%);
            // margin: 0 2vw;
            width: 100%;
            min-height: 12vh;
            padding: 2vw 2vw;
            white-space: normal;
            background: linear-gradient(180deg, #FFF 21.88%, rgba(255, 255, 255, 0.25) 100%);
            box-shadow: 0px 0px 20px 0px #0EB7FF;
            border-radius: 3vw;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #000;
            font-family: 'Microsoft YaHei';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: 1.1vw;
            transition: 1s;

            &:hover {
                background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
                color: #fff;

                &:before {
                    border-color: #0EB7FF transparent transparent transparent;
                }
            }

            @media screen and (max-width: 991px) {
                border-radius: 80px;
                font-size: 16px;
                line-height: normal;
                padding-bottom: 0;
                padding: 0 2vh;
                min-height: 10vh;
            }

            @media screen and (max-width: 667px) {
                min-height: 10vh;
                font-size: 14px;
                line-height: normal;
                padding-bottom: 0;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                line-height: normal;
                padding-bottom: 0;
            }
        }

        .specificEvent:nth-child(even) .descriptionEventDetails {
            margin-top: -17vh;

            @media screen and (max-width: 991px) {
                margin-top: -14vh;
            }
        }

        .specificEvent:nth-child(odd) .descriptionEventDetails {
            margin-top: 3vh;

            @media screen and (max-width: 991px) {
                margin-top: 2vh;
            }
        }

        .specificEvent:nth-child(even) .descriptionEventDetails:before {
            content: "";
            position: absolute;
            top: -26%;
            left: 52%;
            width: 0;
            height: 0;
            border-style: solid;
            transform: rotateX(0);
        }

        .specificEvent:nth-child(odd) .descriptionEventDetails:before {
            content: "";
            position: absolute;
            top: 12%;
            left: 52%;
            width: 0;
            height: 0;
            border-style: solid;
            transform: rotateX(180deg);
        }


        .descriptionEventDetails::before {
            top: 99%;
            border-width: 1vw 1vw 0 0;
            border-color: white transparent transparent transparent;
            z-index: 0;

            @media screen and (max-width: 991px) {
                border-width: 8px 8px 0 0;
                display: none;
            }
        }
    }

    .longtermPayment {
        padding-bottom: 20vh;

        @media screen and (max-width: 991px) {
            padding-bottom: 5vh;
        }

        .headerAbsolutePanel1 {
            left: 0;
            margin-top: -15vh;
            position: sticky;
            z-index: 4;

            @media screen and (max-width: 991px) {
                margin-top: -10vh;
                z-index: 4;
            }

            @media screen and (max-width: 667px) {
                margin-top: -10vh;
            }

            .headerPanel1Bg {
                border-radius: 0px 110px 110px 0px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 30.27%, rgba(255, 255, 255, 0.45) 100%);
                box-shadow: 0px 2px 35px 0px #0EB7FF;
                backdrop-filter: blur(1px);
                padding: 5vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 30.27%, rgba(255, 255, 255, 0.2) 100%);
                    backdrop-filter: blur(10px);
                    padding: 2rem 0;
                }

                @media screen and (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 0;
                }
            }

            .headerPanel1Content {
                .headerPanel1Title {
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;
                    background: var(--Linear, linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 18px;
                    }
                }

                .headerPanel1SmallTitle {
                    font-family: 'Microsoft YaHei';
                    font-size: 2vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;
                    background: var(--Linear, linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width: 991px) {
                        font-size: 30px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 15px;
                    }
                }

                .headerPanel1Header {
                    color: #5A5C86;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 1.3vw;

                    @media screen and (max-width: 991px) {
                        font-size: 18px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 14px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 11px;
                        line-height: normal;
                        padding-bottom: 0;
                    }
                }

                .headerPanel1Desc {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.3vw;
                    padding-bottom: 1vh;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 14px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 11px;
                        line-height: normal;
                        padding-bottom: 0;
                    }
                }

                .paymentStepIndex {
                    background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
                    padding: 0.2vw 0.6vw;
                    margin-right: 0.5vw;
                    border-radius: 50%;
                    color: #fff;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.8vw;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 1.3vw;

                    @media screen and (max-width: 991px) {
                        padding: 0.1rem 0.5rem;
                        margin-right: 0.5rem;
                        font-size: 18px;
                        line-height: 25px;
                    }

                    @media screen and (max-width: 667px) {
                        padding: 0.3rem 0.6rem;
                        font-size: 14px;
                        line-height: normal;
                    }

                    @media screen and (max-width: 300px) {
                        padding: 0.3rem 0.6rem;
                        font-size: 11px;
                        line-height: normal;
                    }
                }
            }
        }

        .headerAbsolutePanel2 {
            position: absolute;
            left: 0;
            top: 15vh;
            z-index: 2;

            @media screen and (max-width: 991px) {
                position: relative;
                top: 3vh;
                z-index: 1;
            }

            .headerPanel2Bg {
                border-radius: 110px 0 0 110px;
                background: var(--linear, linear-gradient(89deg, #0EB7FF 0.88%, #2363C3 43.97%, #1A1D57 94.26%));
                padding: 6vh 0;
                // min-height: 55vh;

                @media screen and (max-width: 991px) {
                    border-radius: 50px 0 0 40px;
                    margin-top: -9vh;
                    padding-top: 6vh;
                    min-height: 55vh;
                }
            }

            .headerPanel2Content {
                padding-left: 6.5vw;

                .paymentBoxDiv {
                    border-radius: 70px;
                    background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
                    box-shadow: 0px 0px 69px 0px #FFF inset, 0px 4px 79px 0px rgba(34, 85, 172, 0.12);
                    min-height: 45vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 991px) {
                        min-height: 35vh;
                        width: 550px;
                        margin-right: 20px;
                    }

                    @media screen and (max-width: 667px) {
                        min-height: 35vh;
                        width: 300px;
                        margin-right: 20px;
                    }

                    img {
                        width: 12vh;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }

                    .headerPanel2Header {
                        color: #5A5C86;
                        font-family: 'Microsoft YaHei';
                        font-size: 1vw;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 1.3vw;

                        @media screen and (max-width: 991px) {
                            font-size: 16px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 667px) {
                            font-size: 14px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 300px) {
                            font-size: 11px;
                            line-height: normal;
                            padding-bottom: 0;
                        }
                    }

                    .headerPanel2Desc {
                        color: #000;
                        font-family: 'Microsoft YaHei';
                        font-size: 1vw;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.3vw;
                        padding-bottom: 1vh;

                        @media screen and (max-width: 991px) {
                            font-size: 16px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 667px) {
                            font-size: 14px;
                            line-height: normal;
                            padding-bottom: 0;
                        }

                        @media screen and (max-width: 300px) {
                            font-size: 11px;
                            line-height: normal;
                            padding-bottom: 0;
                        }
                    }
                }

                #paymentBoxDiv1 {
                    margin-top: -8vh;

                    @media screen and (max-width: 991px) {
                        margin-top: 0vh;
                    }
                }

                #paymentBoxDiv2 {
                    margin-top: -30vh;

                    @media screen and (max-width: 991px) {
                        margin-top: 0vh;
                    }
                }

                #paymentBoxDiv3 {
                    margin-top: -15vh;

                    @media screen and (max-width: 991px) {
                        margin-top: 0vh;
                    }
                }
            }

            .tncPaymentHorizontalScroll {
                display: flex;
                justify-content: start;
                align-items: center;
                width: 1200px;

                @media screen and (max-width: 667px) {
                    width: 1000px;
                }
            }

            ::-webkit-scrollbar {
                width: 0px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0);
                border-radius: 50px;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: transparent !important;
                border-radius: 50px;
                width: 0px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: transparent !important;
                width: 0px;
            }

            .tncPaymentHorizontalScrollX {
                overflow-x: auto;
                position: absolute;
                left: 0;

            }
        }
    }
}