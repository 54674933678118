.home {

    #homeHeader {

        .homeHeaderBg {
            min-height: 100vh;
            // background-image: url(../img/home/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 3;

            @media (max-width: 991px) {
                min-height: 70vh;
                // background-image: url(/assets/img/home/headerMobileBg.webp);
                background-size: 100% 100%;
                position: sticky;
            }

            @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media (max-width: 667px) {
                min-height: 50vh;
                // background-image: url(/assets/img/home/headerMobileBg.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }

        .headerContentPos {
            width: 100%;
            position: absolute;
            top: 11%;

            @media (max-width: 991px) {
                top: 15%;
            }

            @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                top: 18%;

            }

            @media (max-width: 667px) {
                top: 23%;
            }
        }

        .headerTitle {
            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.8vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: 991px) {
                    font-size: 3rem;
                }

                @media (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }

        .knowMoreBtnPos {
            margin-top: 5vh;

            @media (max-width: 991px) {
                margin-top: 2rem;
            }

            @media (max-width: 667px) {
                margin-top: 1.5rem;
            }

            @media (max-width: 300px) {
                margin-top: 1rem;
            }
        }

        .knowMoreBtn {
            border-radius: 44px;
            width: fit-content;
            background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.25) 100%);
            border-radius: 3vw;
            box-shadow: 0px 2px 35px 0px #0EB7FF;
            backdrop-filter: blur(3px);
            cursor: pointer;
            padding: 1vw 2.5vw;

            @media (max-width: 991px) {
                padding: 15px 40px;
                border-radius: 50px;
            }

            @media (max-width: 667px) {
                padding: 10px 30px;
                border-radius: 50px;
            }

            @media (max-width: 300px) {
                padding: 8px 20px;
                border-radius: 50px;
            }

            span {
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 0.8vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width: 991px) {
                    font-size: 14px;
                }

                @media (max-width: 667px) {
                    font-size: 13px;
                }

                @media (max-width: 300px) {
                    font-size: 10px;
                }
            }

            &:hover {
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                span {
                    background: white;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

        }

        .headerAbsolutePanel1 {
            // position: absolute;
            left: 0;
            margin-top: -15vh;
            position: sticky;
            z-index: 4;

            @media (max-width: 991px) {
                margin-top: -10vh;
            }

            @media (max-width: 667px) {
                margin-top: -10vh;
            }

            .headerPanel1Bg {
                border-radius: 0px 110px 110px 0px;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                padding: 5vh 0;

                @media (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    padding: 4rem 0;
                }

                @media (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 0;
                }
            }

            .headerPanel1Content {
                .headerPanel1Title {
                    color: var(--ffffff, #FFF);
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;

                    @media (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media (max-width: 300px) {
                        font-size: 18px;
                    }
                }

                .headerPanel1Desc {
                    color: #C1E1F2;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 3vh;

                    @media (max-width: 991px) {
                        font-size: 16px;
                    }

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }
            }
        }

        .headerAbsolutePanel2 {
            position: absolute;
            left: 0;
            top: 0vh;
            z-index: 2;

            .headerPanel2Bg {
                border-radius: 110px 0 0 110px;
                background: #1A1D57;
                padding: 4vh 0;

                @media (max-width: 991px) {
                    border-radius: 110px 0 0 110px;
                    padding: 3rem 0;
                }

                @media (max-width: 667px) {
                    border-radius: 50px 0 0 40px;
                    padding: 2rem 0;
                }
            }

            .headerPanel2Content {
                padding-top: 18vh;

                @media (max-width: 991px) {
                    padding-top: 32vh;
                }


                @media (max-width: 667px) {
                    padding-top: 28vh;
                }

                .headerIconSize {
                    width: 5vw;
                    aspect-ratio: 2/1;
                    object-fit: contain;

                    @media (max-width: 991px) {
                        width: 100px;
                    }

                    @media (max-width: 667px) {
                        width: 70px;
                    }
                }

                .headerPanel2Desc {
                    color: #C1E1F2;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 2vh;

                    @media (max-width: 991px) {
                        font-size: 16px;
                        padding-bottom: 0vh;
                    }

                    @media (max-width: 667px) {
                        font-size: 14px;
                        padding-bottom: 0vh;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                        padding-bottom: 0vh;
                    }
                }
            }
        }
    }

    .secondHomeSection {
        // background-image: url(../img/home/secondHomeBg.webp);
        // background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding-top: 30vh;
        padding-bottom: 10vh;

        @media (max-width: 991px) {
            padding-top: 40vh;
        }

        @media (max-width: 667px) {
            padding-top: 50vh;
        }


        .secondHomeTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 40px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }


            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .secondHomeContent {
            border-radius: 95px;
        }

        #secondHomeSection1 {
            min-height: 40vh;
            border-radius: 95px 0 0 0;

            .bg-image{
                background-image: url(../img/home/secondHome1.webp);
                background-size: cover;
                background-position: center;

            }

            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }

                }
            }

            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;
                border-radius: 95px 0 0 0;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }


            }
        }

        #secondHomeSection2 {
            min-height: 40vh;
            
            .bg-image{
                background-size: cover;
                background-position: center;
                background-image: url(../img/home/secondHome2.webp);
            }

            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }
            }

            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        #secondHomeSection3 {
            min-height: 40vh;
            border-radius: 0 95px 0 0;
            
            .bg-image{
                background-size: cover;
                background-position: center;
                background-image: url(../img/home/secondHome3.webp);
                
            }

            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }
            }

            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;
                border-radius: 0 95px 0 0;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        #secondHomeSection4 {
            min-height: 40vh;
            border-radius: 0 0 0 95px;
            
            .bg-image{
                
                background-image: url(../img/home/secondHome4.webp);
                background-size: cover;
                background-position: center;
            }
            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }

                }
            }

            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;
                border-radius: 0 0 0 95px;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        #secondHomeSection5 {
            min-height: 40vh;
            
            .bg-image{
                background-image: url(../img/home/secondHome5.webp);
                background-size: cover;
                background-position: center;
                
            }
            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }

                }
            }

            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        #secondHomeSection6 {
            min-height: 40vh;
            
            .bg-image{
                
                background-position: center;
                background-size: cover;
                background-image: url(../img/home/secondHome6.webp);
            }
            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }

                }
            }


            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }


            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        #secondHomeSection7 {
            min-height: 40vh;
            border-radius: 0 0 95px 0;
            .bg-image{
                background-size: cover;
                background-position: center;
                background-image: url(../img/home/secondHome7.webp);
            }

            .descSection {
                position: absolute;
                bottom: 10%;
                left: 10%;
                right: 10%;

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }

                }
            }


            @media (max-width: 991px) {
                border-radius: 40px;
                width: 350px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                width: 280px;
                min-height: 30vh;
                margin: 0 15px;
            }

            @media (max-width: 300px) {
                border-radius: 40px;
                width: 220px;
                min-height: 30vh;
                margin: 0 10px;
            }

            .secondHomeInner {
                background-color: #D9D9D9;
                opacity: 0.8;
                min-height: 40vh;
                border-radius: 0 0 95px 0;

                @media (max-width: 991px) {
                    border-radius: 40px;
                    width: 350px;
                    min-height: 30vh;
                }

                @media (max-width: 667px) {
                    border-radius: 40px;
                    width: 280px;
                    min-height: 30vh;
                }

                span {
                    color: #000;
                    font-family: 'Microsoft YaHei';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 667px) {
                        font-size: 14px;
                    }

                    @media (max-width: 300px) {
                        font-size: 11px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                    opacity: 0.8;
                    mix-blend-mode: multiply;

                    /* Change the text color of span inside descSection */
                    ~.descSection span {
                        color: white;
                    }
                }
            }
        }

        .pinSection {
            position: absolute;
            top: 5%;
            left: 5%;
            width: 100px;

            @media (max-width: 991px) {
                width: 90px;
            }

            @media (max-width: 667px) {
                width: 80px;
            }

            @media (max-width: 300px) {
                width: 60px;
            }

            img {
                width: 100%;
            }
        }

        .horizontalDragOuter {
            width: 100%;
            overflow: scroll;

            .horizontalDrag {
                width: 2100px;
                overflow-x: scroll;
                display: flex;

                @media (max-width: 300px) {
                    width: 1800px;
                }
            }

        }

        ::-webkit-scrollbar {
            width: 0px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0);
            border-radius: 50px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent !important;
            border-radius: 50px;
            width: 0px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: transparent !important;
            width: 0px;
        }
    }

    .videoSection {

        iframe {
            width: 100%;
            height: 60vh;

            @media (max-width: 667px) {
                height: 50vh;
            }
        }

        .futureBg {
            // background-image: url(../img/home/futureBg.webp);
            background-size: cover;
            background-position: center;
        }

        .futureTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 40px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .futureBgDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 1vh;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                padding-top: 0;
            }
        }

        .futureBgPointDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 1.5vh;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 11px;
            }
        }

        .futureSliderPos {
            position: absolute;
            top: -5vh;
            right: 0;

            @media (max-width: 991px) {
                position: relative;
                top: 0;
            }
        }

        .emptyFutureContainer {
            opacity: 0.5;
            background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
            filter: blur(48.5px);
            width: 100%;
            height: 40vh;
        }

        .futureSliderBg {
            border-radius: 95px 0 0 95px;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 5vh 3vw;

            box-shadow:
                rgba(14, 183, 255, 0.17) 20px -23px 150px 125px inset,
                rgba(14, 183, 255, 0.15) 0px -36px 30px 0px inset,
                rgba(14, 183, 255, 0.1) 0px -79px 40px 0px inset,
                0px 0px 16.5px rgba(35, 99, 195, 0.6),
                rgba(14, 183, 255, 0.09) 0px 16px 8px,
                rgba(14, 183, 255, 0.09) 0px 8px 4px,
                rgba(14, 183, 255, 0.09) 0px 4px 2px,
                rgba(14, 183, 255, 0.06) 0px 2px 1px;
            backdrop-filter: blur(18px);


            @media (max-width: 991px) {
                padding: 3rem 1rem;
                border-radius: 50px;
            }

            .carousel .control-dots {
                text-align: left;
                margin: 0 0;
                left: 5%;

                @media (max-width: 991px) {
                    text-align: left;
                    left: 5%;
                }
            }

            .carousel .control-dots .dot {
                width: 6vw;
                height: 0.8vh;
                border-radius: 50px;
                position: sticky !important;

                @media (max-width:991px) {
                    width: 70px;
                }

                @media (max-width:667px) {
                    width: 50px;
                }

                @media (max-width: 300px) {
                    width: 40px;
                }
            }

            .carousel .control-dots .dot.selected {
                width: 6vw;
                height: 0.8vh;
                border: 50px;
                background: linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%);

                @media (max-width:991px) {
                    width: 70px;
                }

                @media (max-width:667px) {
                    width: 50px;
                }

                @media (max-width: 300px) {
                    width: 40px;
                }
            }

            .carousel.carousel-slider .control-arrow {
                background: transparent;
                position: absolute;
                bottom: 0;

                opacity: 1;
                border-radius: 50%;
                top: 80%;

                @media (max-width: 991px) {
                    display: none;
                }
            }

            .carousel .control-prev.control-arrow {
                left: auto;
                right: 9% !important;

            }

            .carousel .control-next.control-arrow:before {
                content: url(../img/home/sliderNext.webp) !important;
            }

            .carousel .control-prev.control-arrow:before {
                content: url(../img/home/sliderPrev.webp) !important;
                border-right: 0px;
            }

            .carousel-img {
                position: absolute;
                right: 0;
                bottom: 0%;
                z-index: 999;
                text-align: right;

                &.left {
                    right: 13%;

                    @media (max-width:667px) {
                        right: 25%;
                    }
                }

                &.right {
                    right: 5%;
                }
            }

            .prevImg {
                width: 3vw;

                @media (max-width:991px) {
                    width: 20%;
                }

                @media (max-width:667px) {
                    width: 15%;
                }

                @media (max-width:300px) {
                    width: 30px;
                }
            }

            .nextImg {
                width: 3vw;

                @media (max-width:991px) {
                    width: 20%;
                }

                @media (max-width:667px) {
                    width: 15%;
                }

                @media (max-width:300px) {
                    width: 30px;
                }
            }
        }

        .futureSliderTitle {
            font-family: 'Microsoft YaHei';
            font-size: 1.7vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 2vh;

            @media (max-width: 991px) {
                font-size: 25px;
                padding-bottom: 1vh;
            }

            @media (max-width: 667px) {
                font-size: 22px;
                padding-bottom: 1vh;
            }

            @media (max-width: 300px) {
                font-size: 20px;
                padding-bottom: 1vh;
            }
        }

        .futurePoint {
            width: 0.4vw;
            height: 2vh;
            border-radius: 11px;
            background: linear-gradient(32deg, #232BC3 0%, #0EB7FF 100%);

            @media (max-width: 991px) {
                width: 8px;
                height: 20px;
            }

            @media (max-width: 667px) {
                width: 8px;
                height: 20px;
            }

            @media (max-width: 300px) {
                width: 8px;
                height: 20px;
            }
        }
    }

    .mm2hPlan-outer {
        margin-top: 18vh;
        background: linear-gradient(66deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.4vh 0 0 0;
        border-radius: 110px;
        position: sticky;
        z-index: 1;
        background-position: 30% center;
        /* Adjust the percentage value as needed */
        background-size: 200% 100%;
        /* Adjust the size as needed */

        @media (max-width: 991px) {
            padding: 5px 0 0 0;
            border-radius: 40px;
            margin-top: 8vh;
        }

        .mm2hPlan {
            border-radius: 110px;
            min-height: 100vh;

            .bg-image{
                background-image: url(/assets/img/home/mm2hBg.webp);
                background-size: 100% 100%;
            }

            @media (max-width: 991px) {
                background-size: cover;
                background-position: bottom center;
                border-radius: 40px;
            }

            .slick-next:before {
                display: none;
            }

            .slick-prev:before {
                display: none;
            }

            .slick-next {
                width: 3vw;
                height: 3vw;
                margin-right: -2vw;

                @media (max-width:991px) {
                    width: 50px;
                    height: 50px;
                    margin-right: -15px;
                }

                @media (max-width:667px) {
                    display: none;
                }
            }

            .slick-prev {
                width: 3vw;
                height: 3vw;
                margin-left: -2vw;

                @media (max-width:991px) {
                    width: 50px;
                    height: 50px;
                    margin-left: -15px;
                }

                @media (max-width:667px) {
                    display: none;
                }
            }
        }

        .mm2hTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #fff;
            padding-top: 5vh;

            @media (max-width: 991px) {
                font-size: 40px;
                padding-top: 0;
            }

            @media (max-width: 667px) {
                font-size: 30px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 20px;
                padding-top: 0;
            }
        }

        .mm2hItemText {
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 18px;
            }

            @media (max-width: 667px) {
                font-size: 15px;
            }

            @media (max-width: 300px) {
                font-size: 13px;
            }
        }

        .mm2hItem {
            margin-top: 8vh;
            margin-bottom: 5vh;
            position: relative;

            @media (max-width: 991px) {
                margin-top: 3vh;
                margin-bottom: 3vh;
            }

            .mm2hItemIndex {
                position: absolute;
                background-color: #1871E1;
                border-radius: 50%;
                top: -2vh;
                width: 8vh;
                height: 8vh;
                left: 0;
                right: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 991px) {
                    width: 60px;
                    height: 60px;
                    top: 0%;
                }

                @media (max-width: 667px) {
                    width: 60px;
                    height: 60px;
                    top: -10%;
                }

                @media (max-width: 300px) {
                    width: 50px;
                    height: 50px;
                    top: -10%;
                }

                span {
                    color: var(--ffffff, #FFF);
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    @media (max-width: 991px) {
                        font-size: 20px;
                    }

                    @media (max-width: 667px) {
                        font-size: 16px;
                    }

                    @media (max-width: 300px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .mm2hLine {
            width: 30%;
            position: absolute;
            top: 50%;
            right: -15%;
            z-index: -1;
        }

        .slick-active:nth-child(3) {
            .mm2hLine {
                display: none;
            }
        }

        .mm2hItemBg {
            border-radius: 30px;
            background-image: url(../img/home/mm2hItemBg.webp);
            background-size: 100% 100%;
            min-height: 40vh;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 7vw;
                aspect-ratio: 2/1;
                object-fit: contain;

                @media (max-width: 991px) {
                    width: 150px;
                }

                @media (max-width: 300px) {
                    width: 120px;
                }
            }

            @media (max-width:667px) {
                min-height: 28vh;
            }
        }

        .accordion__button {
            background-color: transparent !important;
            padding-top: 2vh;
        }

        .accordionSectionTitle {
            color: #FFF;
            font-family: 'Microsoft YaHei';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 3vh;

            @media (max-width: 991px) {
                font-size: 30px;
            }

            @media (max-width: 667px) {
                font-size: 25px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .accordionPosition {
            padding-top: 3vh;
            padding-bottom: 10vh;

            @media (max-width: 991px) {
                padding-top: 2rem;
                padding-bottom: 3rem;
            }
        }

        .accordionTitle {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.2vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
            }

            @media (max-width: 300px) {
                font-weight: 500;
                font-size: 15px;
                line-height: 29px;
            }
        }

        .accordionTitle-inactive {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
            }

            @media (max-width: 300px) {
                font-size: 15px;
            }
        }

        .accordionContent {
            font-family: 'Urbanist-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 1vw;
            line-height: 2vw;
            text-align: left;
            color: #C1E1F2;

            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 25px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 22px;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                line-height: 15px;
            }
        }


        .accordion__button {
            background: transparent
        }

        .accordion__panel {
            padding-left: 8px;
            padding-top: 4px !important;
        }


        .accordion__button:before {
            display: none;
        }

        .lh-1 {
            line-height: 1;
        }

        .accordion__button::before {
            display: none !important;
        }

        .accordionIcon {
            font-size: 1vw;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        .selectedExpandIcon {
            float: right;
            color: #0EB7FF;

        }

        .nonselectedExpandIcon {

            float: right;
            color: #0EB7FF;

        }


        .borderBottomWhite {
            border-bottom: 1px solid #334170;
            padding-bottom: 1vh;
        }

        .borderTopWhite {
            padding-top: 1vh;
            border-top: 1px solid #334170;
        }
    }

    .customerFeedback {

        .customerFeedbackBg {
            // background-image: url(../img/home/futureBg.webp);
            background-size: cover;
            background-position: center;
            padding-top: 5vh;
        }

        .customerFeedbackSliderBg {
            border-radius: 0px 0px 0px 110px;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            min-height: 80vh;
            margin-top: -15vh;
            z-index: 0;
            position: relative;


            @media (max-width: 991px) {
                border-radius: 50px 0 0 50px;
                margin-top: -6vh;
                min-height: 420px;
            }

        }

        .customerFeedbackSlider {
            width: 75vw;
            left: -35%;
            top: 8%;

            @media (min-width:668px) and (max-width:991px) {
                left: -10%;
                top: -10%;
                width: 100vw;

            }

            @media (max-width:667px) {
                top: 0%;
                left: 0%;
                width: 100%;

            }
        }

        .dot {
            width: 15%;
            height: 14%;
            border-radius: 0 !important;
            background: transparent !important;
            aspect-ratio: 1/1;
            object-fit: contain;
        }

        .customerFeedbackTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 40px;
            }

            @media (max-width: 667px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .futureBgDesc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 1vh;

            @media (max-width: 991px) {
                font-size: 17px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                padding-top: 0;
            }

            @media (max-width: 300px) {
                font-size: 11px;
                padding-top: 0;
            }
        }

        .customerFeedbackCard {
            &.active {
                border-radius: 80px;
                background: #FFF;
                opacity: 1;
                margin-left: 10.8vw;
                z-index: 50;
                position: sticky;
                box-shadow: inset 0px 0px 16.5px rgba(35, 99, 195, 0.6);
                transform: scale(1);
                transition: 1s;
                transform-origin: center;

                @media(max-width:667px) {
                    margin-left: 18vw;

                    border-radius: 34.224px;
                }

            }

            &.inactive {
                border-radius: 80px;
                opacity: 0.5;
                z-index: 0;
                background: linear-gradient(135deg, #FFF 47.27%, rgba(255, 255, 255, 0.62) 100%);
                transform: scale(0.8);
                transition: 1s;
                transform-origin: center;

                @media(max-width:667px) {
                    transform-origin: center;
                    border-radius: 34.224px;
                }

            }



        }

        .rightSliderImg {
            position: absolute;
            left: 20%;
            width: 25%;

            @media (max-width:991px) {
                left: 15%;
            }

            @media (max-width:667px) {
                left: 20%;
            }
        }

        .leftSliderImg {
            width: 25%;
        }

        .cardMinHeight {
            min-height: 40vh;

            @media (max-width:991px) {
                min-height: 350px;
            }

            @media (max-width:667px) {
                min-height: 200px;
            }
        }

        .feedback-desc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 15px;
            }

            @media (max-width: 300px) {
                font-size: 13px;
            }
        }

        .feedback-name {
            color: #1A1D57;
            font-family: 'Microsoft YaHei';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 20px;
            }

            @media (max-width: 300px) {
                font-size: 15px;
            }
        }


        .videoSlider {
            width: 100%;

            iframe {
                width: 100%;
                height: 40vh;
                border-radius: 1vw;

                @media (max-width: 991px) {
                    height: 40vh;
                    border-radius: 40px;
                }

                @media (max-width: 667px) {
                    height: 30vh;
                    border-radius: 40px;
                }
            }

            img {
                border-radius: 60px;
                width: 60%;

                @media (max-width: 667px) {
                    border-radius: 30px;
                    width: 100%;
                }
            }
        }

        .customerSlider {
            padding-top: 5vh;
            padding-bottom: 10vh;

            .slick-next:before {
                display: none;
            }

            .slick-prev:before {
                display: none;
            }

            .slick-next {
                width: 6vw;
                height: 75px;
                margin-right: 15%;
                top: 50%;
                z-index: 999;

                @media (max-width:991px) {
                    margin-right: -15px;
                    width: 100px;
                }

                @media (max-width:667px) {
                    margin-right: 0;
                    width: 80px;
                    right: -4%;
                }
            }

            .slick-prev {
                width: 6vw;
                height: 75px;
                margin-left: 15%;
                top: 50%;
                z-index: 999;

                @media (max-width:991px) {
                    margin-left: -15px;
                    width: 100px;
                }

                @media (max-width:667px) {
                    margin-left: 0;
                    width: 80px;
                    left: -4%;
                }
            }

            @media (min-width:992px) {


                .slick-slide {
                    transform: scale(1);
                    transition: 1s;
                    filter: blur(10px);
                    position: relative;
                    z-index: 1;

                    @media (max-width:991px) {
                        transform: scale(0.8);
                    }

                    @media (max-width:667px) {
                        transform: scale(0.8);
                    }

                    .view-btn {
                        display: none;
                    }
                }

                .slick-current {
                    transform: scale(1);
                    transform-origin: bottom;
                    transition: 1s;
                    filter: blur(0);
                    text-align: center;
                    z-index: 10;

                    @media (max-width:991px) {
                        transform: scale(1);
                    }

                    .videoSlider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        transition: 1s;
                        transform: scale(1.6);
                    }

                    iframe {
                        text-align: center;
                        margin: auto;
                    }
                }

                .slick-list {
                    padding: 13vh 0 !important;
                }
            }
        }
    }

    .appointmentBg {
        position: relative;
        // background-image: url('../../assets/img/home/appointmentBg.webp');
        background-size: 100% 100%;
        padding-bottom: 10vh;

        .appointmentform {
            border-radius: 110px;
            background: linear-gradient(180deg, #FFF 57.94%, rgba(255, 255, 255, 0.25) 100%);
            box-shadow: 0px 0px 16.5px rgba(35, 99, 195, 0.6);
            padding-bottom: 20vh;

            @media (max-width:667px) {
                border-radius: 22px;
            }
        }

        .appointment-title {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.4vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width: 991px) {
                font-size: 35px;
            }

            @media (max-width: 667px) {
                font-size: 26px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .appointment-label {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            // text-transform: uppercase;

            @media (max-width:991px) {
                font-size: 18px;
            }

            @media (max-width:667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }

        .input-border {
            border-radius: 38px;
            background: #EDF7FF;
            font-size: 1vw;
            margin-bottom: 1vh;

            @media (max-width:991px) {
                font-size: 18px;
                margin-bottom: 0;
            }

            @media (max-width:667px) {
                font-size: 15px;
                margin-bottom: 0;
            }

            option {
                font-size: 15px;
            }

        }

        .yourmessage-input {
            border-bottom: 0px !important;
            max-height: 300px !important;
            background: #EDF7FF !important;
        }

        .border-none {
            border: none !important;
        }

        .input-transparent {
            background: transparent !important;
            padding: 1vh 1vw;
        }

        .submit-btn {
            border-radius: 44px;
            width: fit-content;
            box-shadow: 0px 12px 18px 3px #0EB7FF;
            backdrop-filter: blur(3px);
            border-radius: 3vw;
            cursor: pointer;
            padding: 1vw 2.5vw;

            @media (max-width: 991px) {
                padding: 15px 40px;
                border-radius: 50px;
            }

            @media (max-width: 667px) {
                padding: 10px 40px;
                border-radius: 50px;
            }

            @media (max-width: 300px) {
                padding: 10px 30px;
                border-radius: 50px;
            }

            span {
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width:991px) {
                    font-size: 17px;
                }

                @media (max-width:667px) {
                    font-size: 12px;
                }
            }

            &:hover {
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                span {
                    background: white;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    .perfectBg-outer {
        margin-top: -20vh;
        background: linear-gradient(80deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.2vh 0 1.2vh 0;
        border-radius: 110px;
        position: sticky;
        z-index: 7;

        @media (max-width: 991px) {
            border-radius: 40px;
            padding: 4px 0 4px 0;
        }

        .perfectBg {
            padding: 30vh 0;
            
            .bg-image{
                 background-image: url(../img/home/perfectBg.webp);
                 background-size: 100% 100%;
                 border-radius: 110px;
             }

            @media (max-width: 991px) {
                padding: 15vh 0;
                border-radius: 60px;
                background-size: cover;
                background-position: center;
            }

            @media (max-width: 667px) {
                border-radius: 40px;
                background-size: cover;
                background-position: center;
                padding: 15vh 0;
            }

            .perfect-title {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 2vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media (max-width: 991px) {
                    font-size: 40px;
                }

                @media (max-width: 667px) {
                    font-size: 26px;
                }

                @media (max-width: 300px) {
                    font-size: 20px;
                }
            }

            .perfect-desc {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media (max-width:991px) {
                    font-size: 18px;
                }

                @media (max-width:667px) {
                    font-size: 12px;
                }
            }

            .startJourneyBtnPos {
                margin-top: 5vh;

                @media (max-width:991px) {
                    margin-top: 3rem;
                }
            }

            .submit-btn {
                background-color: #fff;
                border-radius: 44px;
                width: fit-content;
                box-shadow: 0px 12px 18px 3px #0EB7FF;
                backdrop-filter: blur(3px);
                border-radius: 3vw;
                cursor: pointer;
                padding: 1.2vw 2.5vw;

                @media (max-width: 991px) {
                    padding: 15px 40px;
                    border-radius: 50px;
                }

                @media (max-width: 667px) {
                    padding: 10px 40px;
                    border-radius: 50px;
                }

                @media (max-width: 300px) {
                    padding: 10px 30px;
                    border-radius: 50px;
                }

                span {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media (max-width:991px) {
                        font-size: 17px;
                    }

                    @media (max-width:667px) {
                        font-size: 12px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                    span {
                        background: white;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }


    .recentNewsBg {
        padding-top: 25vh;
        margin-top: -15vh;
        border-radius: 0 0 110px 110px;
        position: sticky;
        z-index: 6;
        
        .bg-image{
            background-image: url(../img/home/recentNewsBg.webp);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center bottom;
        }

        @media (max-width:991px) {
            background-size: cover;
            border-radius: 0 0 40px 40px;
        }

        .news-title {
            color: #FFF;
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 40px;
            }

            @media (max-width: 667px) {
                font-size: 26px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
            }
        }

        .recent-img {
            border-radius: 40px;
            width: 100%;
        }

        .selection {
            background: linear-gradient(180deg, #0eb7ff63 0%, rgba(14, 183, 255, 0.00) 100%);
            border-radius: 63px;
        }

        .selection-div {
            border-radius: 63px;
            background: linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%);
            opacity: 1;

            span {
                color: var(--ffffff, #FFF);
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media (max-width: 667px) {
                    font-size: 13px;
                }

                @media (max-width: 300px) {
                    font-size: 12px;
                }
            }

            .active {
                background: white;
                border-radius: 40px;

                span {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 0px;
                    background: var(--linear, linear-gradient(66deg, #2363C3 0%, #0EB7FF 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media (max-width: 667px) {
                        font-size: 13px;
                    }

                    @media (max-width: 300px) {
                        font-size: 12px;
                    }
                }
            }


        }

        .fitContentHeight {
            min-height: 850px;
        }

        .recent-title {
            color: var(--ffffff, #FFF);
            font-family: 'Microsoft YaHei';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 32px;
            }

            @media (max-width: 667px) {
                font-size: 20px;
            }

            @media (max-width: 300px) {
                font-size: 16px;
            }
        }

        .recent-title2 {
            color: var(--ffffff, #FFF);
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 16px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }


        .recent-desc {
            color: #C1E1F2;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 20px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }

        .recent-desc2 {
            color: #C1E1F2;
            font-family: 'Microsoft YaHei';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 16px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }

        .small-txt {
            color: #9EA0B9;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 20px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }

        .small-txt2 {
            color: #9EA0B9;
            font-family: 'Microsoft YaHei';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (max-width: 991px) {
                font-size: 16px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
            }

            @media (max-width: 300px) {
                font-size: 12px;
            }
        }
    }

}