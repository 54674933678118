.schoolDetails {
    .contentIndexText {
        color: #B6E3FC;
        font-family: "Microsoft YaHei";
        font-size: 1.1vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width:991px) {
            font-size: 22px;
        }

        @media screen and (max-width:667px) {
            font-size: 16px;
        }
    }

    .contentIndexText2 {
        color: #B6E3FC;
        font-family: "Microsoft YaHei";
        font-size: 1.6vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width:991px) {
            font-size: 18px;
        }

        @media screen and (max-width:667px) {
            font-size: 12px;
        }
    }

    .schoolDetailsSliderBox {
        border-radius: 4vw;
        background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
        padding: 2vh 7vh;
        min-height: 35vh;
        display: flex !important;
        flex-flow: column;
        justify-content: space-evenly;

        @media screen and (max-width:991px) {
            padding: 0 6vh;
            min-height: 30vh;
            border-radius: 80px;
        }

        @media screen and (max-width:300px) {
            border-radius: 50px;
        }
    }

    
    .longtermAdvantageSliderPos {
        width: 120%;
        right: 0%;
        top: 25%;
        z-index: 10;

        @media screen and (max-width:991px) {
            top: 0%;
            left: 0%;
            right: 0;
            margin: auto;
            width: 100%;
        }

        @media screen and (max-width:667px) {
            top: 0%;
            left: 0%;
            right: 0;
            margin: auto;
            width: 100%;
        }
    }

    .schoolConceptSliderBox {
        border-radius: 80px;
        background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
        padding: 0vh 7vh;
        min-height: 30vh;
        display: flex !important;
        flex-flow: column;
        justify-content: space-evenly;

        @media screen and (max-width:991px) {
            padding: 0 6vh;
            min-height: 25vh;
            border-radius: 50px;
        }
    }

    .oasisDivBox {
        .conceptSubjectText {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 1.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: fit-content;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 24px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .conceptTitleText {
            font-family: 'Microsoft YaHei';
            font-size: 1.1vw;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
    
            @media screen and (max-width: 991px) {
                font-size: 22px;
            }
    
            @media screen and (max-width: 667px) {
                font-size: 18px;
            }
    
            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }
    }
    
    .text-blue {
        color: #1794E6 !important;
    }

    .IGBTitleText {
        font-family: 'Microsoft YaHei';
        font-size: 1.5vw;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }

        @media screen and (max-width: 667px) {
            font-size: 22px;
        }

        @media screen and (max-width: 300px) {
            font-size: 20px;
        }
    }

    .contentTitleText {
        font-family: 'Microsoft YaHei';
        font-size: 1.5vw;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        background: linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }

        @media screen and (max-width: 667px) {
            font-size: 22px;
        }

        @media screen and (max-width: 300px) {
            font-size: 18px;
        }
    }

    .contentDescText {
        color: #41669D;
        text-align: center;
        font-family: 'Microsoft YaHei';
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: normal;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
            line-height: normal;
        }

        @media screen and (max-width: 300px) {
            font-size: 11px;
            line-height: normal;
        }
    }

    .schoolSliderBg {
        border-radius: 90px 0px 0px 90px;
        background: var(--Linear, linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%));
        min-height: 55vh;
        margin: 10vh 0;
        z-index: 0;
        position: relative;

        @media (max-width: 991px) {
            min-height: 40vh;
            margin: -5vh 0vh 0 0;
        }

        @media screen and (max-width:667px) {
            border-radius: 50px 0 0 50px;
            min-height: 41vh;
        }
    }

    .schoolAchievement {
        margin-top: 0;
        background: linear-gradient(66deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.4vh 0 0 0;
        border-radius: 110px;
        position: sticky;
        z-index: 1;
        background-position: 30% center;
        background-size: 100% 100%;

        @media screen and (max-width: 991px) {
            padding: 5px 0 0 0;
            border-radius: 40px;
            margin-top: 8vh;
        }

        .schoolAchievementInner {
            background-image: url(../img/internationalSchool/schoolAchievementBg.webp);
            background-color: #1A1D57;
            background-size: 100% 100%;
            border-radius: 110px;
            padding: 8vh 0;

            @media screen and (max-width: 991px) {
                background-size: cover;
                background-position: bottom center;
                border-radius: 40px;
            }
        }

        .schoolAchievementTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #fff;
            padding-top: 5vh;

            @media screen and (max-width: 991px) {
                font-size: 40px;
                padding-top: 0;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
                padding-top: 0;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
                padding-top: 0;
            }
        }

        .schoolAchievementTitleText {
            color: #fff;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 18px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
            }
        }
    }

    .achievementTextSection {
        border-bottom: 1px solid #41458E;
        padding: 2vh 0;
    }

    .achievementText {
        color: #fff;
        text-align: center;
        font-family: 'Microsoft YaHei';
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;

        @media screen and (max-width: 991px) {
            font-size: 16px;
            line-height: normal;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
            line-height: normal;
        }

        @media screen and (max-width: 300px) {
            font-size: 11px;
            line-height: normal;
        }
    }

    .schoolConceptTitle {
        font-family: 'Microsoft YaHei Bold';
        font-size: 2.6vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 991px) {
            font-size: 3rem;
        }

        @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
            font-size: 1.7rem;
        }

        @media screen and (max-width: 667px) {
            font-size: 1.7rem;
        }

        @media screen and (max-width: 300px) {
            font-size: 1.2rem;
        }
    }

    .conceptEduBg {
        background-image: url(../img/internationalSchool/eduConceptBg.webp);
        background-size: 100% 100%;
        margin-top: -15vh;
        padding: 30vh 0 0 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
            padding: 25vh 0 0 0;
        }

        .rightSliderImg {
            width: 20%;

            @media screen and (max-width: 767px) {
                width: 30%;
            }

            @media screen and (max-width: 300px) {
                width: 40%;
            }
        }

        .leftSliderImg {
            width: 20%;

            @media screen and (max-width: 767px) {
                width: 30%;
            }

            @media screen and (max-width: 300px) {
                width: 40%;
            }
        }

        .sectionDesc {
            color: #000;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3vw;
            padding-bottom: 1vh;

            @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: normal;
                padding-bottom: 0;
            }

            @media screen and (max-width: 667px) {
                font-size: 14px;
                line-height: normal;
                padding-bottom: 0;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                line-height: normal;
                padding-bottom: 0;
            }
        }

        .sectionTitle {
            font-family: 'Microsoft YaHei Bold' !important;
            font-weight: 900;
        }
    }

    .rewardSection {
        .rewardAbsolutePanel1 {
            left: 0;
            top: 5vh;
            position: absolute;
            z-index: 2;

            .rewardPanel1Bg {
                border-radius: 0px 5vw 5vw 0px;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));

                @media screen and (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    padding: 4rem 0;
                }

                @media screen and (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 0;
                }
            }

            .rewardPanel1Content {
                min-height: 22vw;

                @media screen and (max-width: 991px) {
                    min-height: 25vh;
                }

                @media screen and (max-width: 767px) {
                    min-height: 30vh;
                }

                .rewardPanel1Title {
                    color: var(--ffffff, #FFF);
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .rewardAbsolutePanel2 {
            position: sticky;
            left: 0;
            padding-top: 15vh;
            z-index: 4;

            @media screen and (max-width: 991px) {
                padding-top: 30vh;
            }

            .rewardPanel2Bg {
                border-radius: 5vw 0 0 5vw;
                background: linear-gradient(180deg, #FFF 34.27%, #BBE3FA 100%);
                padding: 10vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 110px 0 0 110px;
                    padding: 3rem 0;
                }

                @media screen and (max-width: 667px) {
                    border-radius: 50px 0 0 40px;
                    padding: 2rem 0;
                }
            }
        }

        .futurePoint {
            width: 0.4vw;
            height: 2vh;
            border-radius: 11px;
            background: linear-gradient(32deg, #232BC3 0%, #0EB7FF 100%);

            @media screen and (max-width: 991px) {
                width: 8px;
                height: 20px;
            }

            @media screen and (max-width: 667px) {
                width: 8px;
                height: 20px;
            }

            @media screen and (max-width: 300px) {
                width: 8px;
                height: 20px;
            }
        }

        .rewardPanel2Desc {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 16px;
                padding-bottom: 0vh;
            }

            @media screen and (max-width: 667px) {
                font-size: 14px;
                padding-bottom: 0vh;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                padding-bottom: 0vh;
            }
        }
    }

    .relatedAcademy {
        background-image: url(../img/internationalSchool/relatedAcademyBg.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            background-size: cover;
            padding: 10vh 0 20vh 0;
        }

        @media screen and (max-width: 767px) {
            background-size: cover;
            padding: 10vh 0 15vh 0;
        }

        .schoolSelection {
            img {
                border-radius: 4vw;
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;

                @media screen and (max-width: 991px) {
                    border-radius: 40px;
                }
            }
        }

        .propertyNameTitle {
            color: #1A1D57;
            font-family: 'Microsoft YaHei Bold';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }
        }

        .propertyLocation {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 14px;
            }
        }
    }

    .emptyAcademy {
        padding: 10vh 0;
    }
}