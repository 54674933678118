:root {
    min-width: 250px;
}

@font-face {
    font-family: 'Microsoft YaHei';
    src: url('../fonts/MicrosoftYahei.otf') format('opentype');
    /* Adjust the path to your actual font file */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Microsoft YaHei Bold';
    src: url('../fonts/MicrosoftYahei.otf') format('opentype');
    /* Adjust the path to your actual font file */
    font-weight: normal;
    font-style: normal;
}

a {
    &:hover {
        text-decoration: none !important;
    }

}

.nav-maxWidth {
    max-width: 88%;

    @media screen and (max-width:667px) {
        max-width: 100%;
    }
}

.sidebar-collapse .navbar .navbar-toggler-bar {
    @media screen and (max-width:667px) {
        background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
    }
}

.sidebar-collapse .navbar-collapse:before {
    background: linear-gradient(#2CA8FF, #fff 90%) !important;
}

.navbar-transparent2 {
    padding: 2vh 1rem;
    background-color: rgba(255, 255, 255, 1);
}

.navbar.navbar-transparent {
    padding-top: 0 !important;
    padding: 2vh 1rem !important;
}

.nav-txt {
    color: #A2A4C9;
    text-align: center;
    font-family: 'Microsoft YaHei';
    font-size: 1vw !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    padding: 0vw 0.6vw;
    word-break: keep-all;

    @media screen and (max-width: 991px) {
        font-size: 18px !important;
        color: white;
    }
}

.footerPageInfo {
    justify-content: space-around !important;

    @media screen and (max-width: 767px) {
        flex-flow: wrap;
        justify-content: start !important;

        div {
            width: 50%;
            margin: 0.5vh 0;
            text-align: left;
        }
    }

}

.footer-nav-txt {
    color: #A2A4C9;
    text-align: center;
    font-family: 'Microsoft YaHei';
    font-size: 1vw !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    padding: 0vw 0.6vw;
    word-break: keep-all;

    @media screen and (max-width: 991px) {
        font-size: 18px !important;
        color: #A2A4C9;
    }
}



.active-nav-txt {
    text-align: center;
    font-family: 'Microsoft YaHei';
    font-size: 1vw !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0vw 0.6vw;

    @media screen and (max-width: 991px) {
        font-size: 18px !important;
    }

}

.sidebar-collapse .navbar .navbar-toggler-bar {
    @media screen and (max-width: 991px) {
        background: #1A1D57;
    }
}

.hashLinkStyle {
    text-decoration: none !important;
}

.logoSize {
    width: 12vw;

    @media screen and (max-width: 991px) {
        width: 250px;
    }

    @media screen and (max-width: 300px) {
        width: 200px;
    }
}

.errorTextRed {
    color: red;
    font-size: 0.8vw;

    @media screen and (max-width: 991px) {
        font-size: 16px;
    }

    @media screen and (max-width: 667px) {
        font-size: 15px;
    }

    @media screen and (max-width: 300px) {
        font-size: 13px;
    }
}

// .navbar-nav li:last-child {
//     position: absolute;
//     right: 5%;
//     bottom: 15%;

//     @media screen and (max-width:667px) {
//       left:0%;
//       right:0%;
//     }
// }

.registerButton {
    border-radius: 44px;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #fff;
    box-shadow: 0px 12px 18px -6px #0EB7FF;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);

    span {
        text-align: center;
        font-family: 'Microsoft YaHei';
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width:667px) {
            font-size: 12px;
        }
    }
}

// .registerButton-txt {
//     color: #FFF;
//     text-align: center;
//     font-family: 'Microsoft YaHei', sans-serif;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
// }
.form-control {
    width: 96%;
}


.footerbg {
    padding-top: 15vh;
    margin-top: -10vh;
    position: relative;
    // background-image: url('../../assets/img/footer/footerbg.webp');
    background-size: cover;
}

.footerbg::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.9);
}

.footerImg-margin {
    margin: 4vh 3vw;

    @media screen and (max-width:991px) {
        margin: 0 3rem;
    }
}

.footer-img {
    object-fit: contain;
    width: 6vw;
    height: 6vw;

    @media screen and (max-width:991px) {
        width: 100px;
        height: 100px;
    }

    @media screen and (max-width:667px) {
        width: 60px;
        height: 60px;
    }

}

.ant-picker-suffix {
    color: #2363C3 !important;
}

.footerIcon {
    // background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46z15%, #0EB7FF 100%)) !important;
    // background-clip: text !important;
    // -webkit-background-clip: text !important;
    // -webkit-text-fill-color: transparent !important;

    color: #2363C3;
    font-size: 1vw;

    @media screen and (max-width:991px) {
        font-size: 1.2rem;
    }

}

.footer-title {
    text-align: center;
    font-family: 'Microsoft YaHei';
    font-size: 2.6vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width:991px) {
        font-size: 50px;
    }

    @media screen and (max-width:667px) {
        font-size: 28px;
    }

    @media screen and (max-width: 300px) {
        font-size: 20px;
    }
}

.font-weight-700 {
    font-weight: 700 !important
}

.footer-txt {
    color: #5A5C86;
    font-family: 'Microsoft YaHei';
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width:991px) {
        font-size: 14px;
    }

    @media screen and (max-width: 300px) {
        font-size: 12px;
    }
}

.footer-desc {
    color: #5A5C86;
    font-family: 'Microsoft YaHei';
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 991px) {
        font-size: 14px;
    }

    @media screen and (max-width: 300px) {
        font-size: 12px;
    }
}

.copyright {
    color: #5A5C86;
    text-align: right;
    font-family: 'Microsoft YaHei';
    font-size: 0.7vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media screen and (max-width: 991px) {
        font-size: 11px;
    }
}

.socialMediaIcon {
    width: 2vw;
    aspect-ratio: 2/1;
    object-fit: contain;

    @media screen and (max-width: 991px) {
        width: 40px;
    }
}

.min-height-100 {
    min-height: 100vh;

    // @media screen and (max-width: 667px) {
    //     min-height: auto;
    // }
}

@media screen and (max-width: 300px) {
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 220px !important;
    }

    .nav-open .sidebar-collapse .navbar-translate {
        transform: translate3d(-220px, 0, 0);
    }

    .sidebar-collapse #bodyClick {
        right: 220px;
    }
}

.break-white-space {
    word-break: break-all;
}

.white-space-break {
    white-space: break-spaces;
}

.cursor-pointer {
    cursor: pointer;
}

.eduDropdown {
    .btn {
        background: transparent !important;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        display: flex !important;
        border: none !important;
        align-items: center !important;
    }

    .dropdown-toggle:after {
        font-size: 1vw;
        color: #0EB7FF;

        @media screen and (max-width: 991px) {
            font-size: 1.5vh;
            color: #fff;
        }
    }

    .dropdown-menu {
        overflow: hidden !important;
        border-radius: 1.5vw;
        padding: 1vw;

        @media screen and (max-width: 991px) {
            padding: 0 0 2vh 0;
            position: relative !important;
            height: auto !important;
        }
    }
}

.footer-eduDropdown {
    .btn {
        background: transparent !important;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        display: flex !important;
        border: none !important;
        align-items: center !important;
    }

    .dropdown-toggle:after {
        font-size: 1vw;
        color: #0EB7FF;

        @media screen and (max-width: 991px) {
            font-size: 1.5vh;
        }
    }

    .dropdown-menu {
        overflow: hidden !important;
        border-radius: 1.5vw;
        padding: 1vw;

        @media screen and (max-width: 991px) {

        }
    }
}

.mb-vw-2 {
    margin-bottom: 2vw;

    @media screen and (max-width: 991px) {
        margin-bottom: 2rem;
    }
}

.w-fit-content {
    width: fit-content;
}

.prevArrow {
    width: 100%;
}

.nextArrow {
    width: 100%;
}

.bg-image-parent{ position: relative; }

.bg-image{
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: -1;
}