.contactUs {
    #contactUsHeader {
        .aboutUsHeaderBg {
            min-height: 60vh;
            background-image: url(../img/contactUs/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 3;

            @media screen and (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/contactUs/headerBgMobile.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media screen and (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/contactUs/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }


        .headerTitle {
            text-align: center;

            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.6vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 991px) {
                    font-size: 3rem;
                }

                @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }

        .headerAbsolutePanel1 {
            left: 0;
            margin-top: -15vh;

            @media screen and (max-width: 991px) {
                margin-top: -10vh;
            }

            @media screen and (max-width: 667px) {
                margin-top: -10vh;
            }

            .headerPanel1Bg {
                border-radius: 0px 110px 110px 0px;
                background: linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%);
                padding: 10vh 0;
                position: sticky;
                z-index: 4;

                @media screen and (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    padding: 5rem 0 5rem 3rem;
                }

                @media screen and (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 3rem 0;
                }
            }

            .headerPanel1Content {
                .headerPanel1Title {
                    font-family: 'Microsoft YaHei';
                    font-size: 2vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;
                    color: #fff;
                    margin-bottom: 3vh;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 18px;
                    }
                }

                .contactUsIcon {
                    color: #fff;
                    font-size: 1.2vw;

                    @media screen and (max-width: 991px) {
                        font-size: 26px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 18px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 18px;
                    }
                }

                .headerPanel1Desc {
                    color: #fff;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1vw;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 14px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 11px;
                        line-height: normal;
                        padding-bottom: 0;
                    }
                }

                .wechatQRPos {
                    position: absolute;
                    bottom: -20vh;
                    left: 0;
                    width: 20vh;

                    @media screen and (max-width: 991px) {
                        top: -40%;
                        right: 0% !important;
                        width: 18vh;
                        margin-left: auto;
                    }

                    @media screen and (max-width: 667px) {
                        width: 20vh;
                        position: relative;
                        top: 0;
                        left: 0;
                        margin-left: 0;
                    }
                }

                .wechatImgLayer {
                    border: 8px solid rgba(35, 99, 195, 1);
                    border-radius: 2vw;
                    height: fit-content;

                    @media screen and (max-width: 991px) {
                        border-radius: 20px;
                    }

                    img {
                        border-radius: 1.5vw;

                        @media screen and (max-width: 991px) {
                            border-radius: 15px;
                        }
                    }
                }
            }
        }

        .headerAbsolutePanel2 {
            position: absolute;
            right: 0;
            top: -5vh;
            z-index: 2;

            @media screen and (max-width: 991px) {
                position: sticky;
            }

            .headerPanel2Content {

                iframe {
                    min-height: 80vh;
                    border-radius: 110px 0 0 110px;

                    @media screen and (max-width: 991px) {
                        border-radius: 0 0 0 110px;
                        min-height: 50vh;
                    }
                }
            }
        }
    }

    .contactUsForm {
        background-image: url(../img/contactUs/contactUsFormBg.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top;
        position: relative;
        z-index: 1;
        padding-top: 30vh;
        padding-bottom: 20vh;

        @media screen and (max-width: 991px) {
            padding-top: 15vh;
        }

        @media screen and (max-width: 667px) {
            padding-top: 15vh;
        }

        .appointmentform {
            border-radius: 110px;
            background: linear-gradient(180deg, #FFF 57.94%, rgba(255, 255, 255, 0.25) 100%);
            box-shadow: 0px 0px 16.5px rgba(35, 99, 195, 0.6);
            padding: 5vh 0;

            @media screen and (max-width:667px) {
                border-radius: 22px;
            }
        }

        .appointment-title {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.4vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 991px) {
                font-size: 35px;
            }

            @media screen and (max-width: 667px) {
                font-size: 26px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .appointment-label {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 1vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            // text-transform: uppercase;

            @media screen and (max-width:991px) {
                font-size: 18px;
            }

            @media screen and (max-width:667px) {
                font-size: 14px;
            }

            @media screen and (max-width: 300px) {
                font-size: 12px;
            }
        }

        .input-border {
            border-radius: 38px;
            background: #EDF7FF;
            font-size: 1vw;
            margin-bottom: 1vh;

            @media screen and (max-width:991px) {
                font-size: 18px;
                margin-bottom: 0;
            }

            @media screen and (max-width:667px) {
                font-size: 15px;
                margin-bottom: 0;
            }

            option {
                font-size: 15px;
            }

        }

        .yourmessage-input {
            border-bottom: 0px !important;
            max-height: 300px !important;
            background: #EDF7FF !important;
        }

        .border-none {
            border: none !important;
        }

        .input-transparent {
            background: transparent !important;
            padding: 1vh 1vw;
        }

        .submit-btn {
            border-radius: 44px;
            width: fit-content;
            box-shadow: 0px 12px 18px 3px #0EB7FF;
            backdrop-filter: blur(3px);
            border-radius: 3vw;
            cursor: pointer;
            padding: 1vw 2.5vw;

            @media screen and (max-width: 991px) {
                padding: 15px 40px;
                border-radius: 50px;
            }

            @media screen and (max-width: 667px) {
                padding: 10px 40px;
                border-radius: 50px;
            }

            @media screen and (max-width: 300px) {
                padding: 10px 30px;
                border-radius: 50px;
            }

            span {
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width:991px) {
                    font-size: 17px;
                }

                @media screen and (max-width:667px) {
                    font-size: 12px;
                }
            }

            &:hover {
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));

                span {
                    background: white;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}