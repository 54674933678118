.aboutUs {
    #aboutUsHeader {

        .aboutUsHeaderBg {
            min-height: 60vh;
            background-image: url(../img/aboutUs/headerBg.webp);
            background-size: 100% 100%;
            position: sticky;
            z-index: 3;

            @media screen and (max-width: 991px) {
                min-height: 45vh;
                background-image: url(../img/aboutUs/headerBgMobile.webp);
                background-size: 100% 100%;
                background-position: center;
                position: sticky;
            }

            @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                min-height: 100vh
            }

            @media screen and (max-width: 667px) {
                min-height: 35vh;
                background-image: url(../img/aboutUs/headerBgMobile.webp);
                background-size: 100% 100%;
                position: sticky;
            }
        }


        .headerTitle {
            text-align: center;
            
            span {
                font-family: 'Microsoft YaHei Bold';
                font-size: 2.6vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 991px) {
                    font-size: 3rem;
                }

                @media screen and (min-width: 667px) and (max-width: 991px) and (orientation: landscape) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 667px) {
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 1.2rem;
                }
            }
        }

        .headerAbsolutePanel1 {
            left: 0;
            margin-top: -15vh;
            position: sticky;
            z-index: 4;

            @media screen and (max-width: 991px) {
                margin-top: -10vh;
            }

            @media screen and (max-width: 667px) {
                margin-top: -10vh;
            }

            .headerPanel1Bg {
                border-radius: 0px 110px 110px 0px;
                background: linear-gradient(180deg, #FFF 30.27%, rgba(255, 255, 255, 0.45) 100%);
                box-shadow: 0px 2px 35px 0px #0EB7FF;
                backdrop-filter: blur(3px);
                padding: 5vh 0;

                @media screen and (max-width: 991px) {
                    border-radius: 0px 110px 110px 0px;
                    padding: 4rem 0;
                }

                @media screen and (max-width: 667px) {
                    border-radius: 0px 40px 40px 0px;
                    padding: 1rem 0;
                }
            }

            .headerPanel1Content {
                .headerPanel1Title {
                    font-family: 'Microsoft YaHei';
                    font-size: 2.6vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 124.2%;
                    background: var(--Linear, linear-gradient(89deg, #1A1D57 0.88%, #2363C3 43.97%, #0EB7FF 94.26%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 25px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 18px;
                    }
                }
            }
        }

        .headerAbsolutePanel2 {
            position: absolute;
            left: 0;
            top: -5vh;
            z-index: 2;

            .headerPanel2Bg {
                border-radius: 110px 0 0 110px;
                background: var(--Linear, linear-gradient(89deg, #0EB7FF 0.88%, #2363C3 43.97%, #1A1D57 94.26%));

                @media screen and (max-width: 667px) {
                    border-radius: 50px 0 0 40px;
                }
            }

            .headerPanel2Content {
                padding-top: 23vh;
                padding-bottom: 2vh;

                @media screen and (max-width: 991px) {
                    padding-top: 43vh;
                    padding-bottom: 1vh;
                }


                @media screen and (max-width: 667px) {
                    padding-top: 28vh;
                    padding-bottom: 0vh;
                }

                .headerPanel2Desc {
                    color: #fff;
                    font-family: 'Microsoft YaHei';
                    font-size: 1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.3vw;
                    padding-bottom: 1vh;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 14px;
                        line-height: normal;
                        padding-bottom: 0;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 11px;
                        line-height: normal;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .ourTeamSection {
        // background-image: url(../img/home/secondHomeBg.webp);
        // background-size: 100% 100%;
        position: relative;
        z-index: 1;
        padding-top: 30vh;
        padding-bottom: 10vh;

        @media screen and (max-width: 991px) {
            padding-top: 40vh;
        }

        @media screen and (max-width: 667px) {
            padding-top: 70vh;
        }

        .ourTeamTitle {
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 991px) {
                font-size: 40px;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
            }


            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .teamMemberImgSize {
            width: 100%;
            aspect-ratio: 1.5/1;
        }

        .positionText {
            color: #2363C3;
            font-family: 'Microsoft YaHei';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: 2vw;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 22px;
                line-height: normal;
            }

            @media screen and (max-width: 667px) {
                font-size: 18px;
                line-height: normal;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
                line-height: normal;
            }
        }

        .nameText {
            color: #1A1D57;
            font-family: 'Microsoft YaHei';
            font-size: 1.7vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }

        .descText {
            color: #5A5C86;
            font-family: 'Microsoft YaHei';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 16px;
            }

            @media screen and (max-width: 667px) {
                font-size: 14px;
            }

            @media screen and (max-width: 300px) {
                font-size: 12px;
            }
        }

        .rightSliderImg {
            width: 20%;
        }

        .leftSliderImg {
            width: 20%;
        }
    }

    #missionVision {
        // min-height: 100vh;
        width: 100%;
        background-image: url(../img/aboutUs/missionVisionBg.webp);
        background-size: cover;

        .visionPanel {
            left: 0;
            margin-top: 40vh;
            position: sticky;
            z-index: 4;

            // @media screen and (max-width: 991px) {
            //     margin-top: -10vh;
            // }

            // @media screen and (max-width: 667px) {
            //     margin-top: -10vh;
            // }

            .visionPanelBg {
                border-radius: 110px;
                background-image: url(../img/aboutUs/visionBg.webp);
                background-size: 100% 100%;
                min-height: 60vh;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 991px) {
                    background-size: cover;
                    background-position: center;
                    border-radius: 80px;
                    min-height: 50vh;
                }

                @media screen and (max-width: 667px) {
                    background-size: cover;
                    background-position: center;
                    border-radius: 40px;
                    min-height: 50vh;
                }
            }
        }

        .missionPanel {
            position: absolute;
            left: 0;
            top: 0vh;
            z-index: 2;

            .missionPanelBg {
                border-radius: 110px;
                background-image: url(../img/aboutUs/missionBg.webp);
                background-size: 100% 100%;
                min-height: 60vh;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 991px) {
                    background-size: cover;
                    background-position: center;
                    border-radius: 80px;
                    min-height: 50vh;
                }

                @media screen and (max-width: 667px) {
                    background-size: cover;
                    background-position: center;
                    border-radius: 40px;
                    min-height: 50vh;
                }
            }
        }

        .visionMissionPanelContent {
            .visionMissionTitle {
                color: var(--ffffff, #FFF);
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 2.6vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media screen and (max-width: 991px) {
                    font-size: 40px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 25px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 18px;
                }
            }

            .visionMissionDesc {
                color: var(--ffffff, #FFF);
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5vw;

                @media screen and (max-width: 991px) {
                    font-size: 18px;
                    line-height: normal;
                }

                @media screen and (max-width: 667px) {
                    font-size: 15px;
                    line-height: normal;
                }

                @media screen and (max-width: 300px) {
                    font-size: 12px;
                    line-height: normal;
                }
            }
        }
    }

    .coreValueSection {
        .ourCoreHeaderBg {
            border-radius: 110px 110px 0px 0px;
            background: var(--Linear, linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%));
            padding: 8vh 0;

            @media screen and (max-width: 991px) {
                padding: 5rem 0;
                border-radius: 110px 110px 0px 0px;
            }

            @media screen and (max-width: 667px) {
                padding: 5rem 0;
                border-radius: 40px 40px 0px 0px;
            }
        }

        .ourCoreHeaderSmallTitle {
            color: var(--ffffff, #FFF);
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 16px;
            }
        }

        .ourCoreHeaderBigTitle {
            color: var(--ffffff, #FFF);
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 40px;
            }

            @media screen and (max-width: 667px) {
                font-size: 35px;
            }
        }

        .ourCoreBg {
            background-image: url(../img/aboutUs/ourCoreBg.webp);
            background-size: 100% 100%;
        }

        .coreItemPos {
            margin-top: -5vh;
        }

        .coreItemHeight {
            position: relative;
            height: 50vh;

            @media screen and (max-width: 991px) {
                height: 30vh;
            }

            @media screen and (max-width: 667px) {
                height: 40vh;
            }
        }

        .coreItemHighlightBg {
            position: absolute;
            bottom: 0;
            z-index: 0;
            border-radius: 80px;
            opacity: 0.5;
            background: var(--Linear, linear-gradient(89deg, #2363C3 0.88%, #0EB7FF 94.26%));
            filter: blur(48.5px);
            width: 90%;
            margin: auto;
            height: 40vh;

            @media screen and (max-width: 991px) {
                height: 20vh;
            }

            @media screen and (max-width: 667px) {
                height: 30vh;
            }
        }

        .coreItemBg {
            border-radius: 80px;
            background: linear-gradient(180deg, #FFF 21.88%, rgba(255, 255, 255, 0.25) 100%);
            position: sticky;
            z-index: 1;
            padding: 0 3vw;

            @media screen and (max-width: 991px) {
                padding: 0 3rem;
            }

            @media screen and (max-width: 667px) {
                border-radius: 20px;
                padding: 0 2rem;
            }


        }

        .coreImgSize {
            width: 6vw;
            aspect-ratio: 1/1;
            object-fit: contain;

            @media screen and (max-width: 991px) {
                width: 30%;
            }
        }

        .coreDescText {
            color: #5A5C86;
            text-align: center;
            font-family: 'Microsoft YaHei';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 2vh;

            @media screen and (max-width: 991px) {
                margin-top: 0;
                font-size: 16px;
            }

            @media screen and (max-width: 667px) {
                font-size: 14px;
            }
        }
    }

    .perfectBg-outer {
        // margin-top: -20vh;
        margin-top: 20vh;
        background: linear-gradient(80deg, rgba(35, 43, 195, 0.00) 23.23%, #0EB7FF 100%);
        padding: 1.2vh 0 1.2vh 0;
        border-radius: 110px;
        position: sticky;
        z-index: 7;

        @media screen and (max-width: 991px) {
            border-radius: 40px;
            padding: 4px 0 4px 0;
        }

        .perfectBg {
            padding: 30vh 0;
            .bg-image{
                background-image: url(../img/home/perfectBg.webp);
                background-size: 100% 100%;
                border-radius: 110px;
            }

            @media screen and (max-width: 991px) {
                padding: 15vh 0;
                border-radius: 60px;
                background-size: cover;
                background-position: center;
            }

            @media screen and (max-width: 667px) {
                border-radius: 40px;
                background-size: cover;
                background-position: center;
                padding: 15vh 0;
            }

           .perfect-title {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 2vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media screen and (max-width: 991px) {
                    font-size: 40px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 26px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 20px;
                }
            }

            .perfect-desc {
                color: #FFF;
                text-align: center;
                font-family: 'Microsoft YaHei';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (max-width:991px) {
                    font-size: 18px;
                }

                @media screen and (max-width:667px) {
                    font-size: 12px;
                }
            }

            .startJourneyBtnPos {
                margin-top: 5vh;

                @media screen and (max-width:991px) {
                    margin-top: 3rem;
                }
            }

            .submit-btn {
                background-color: #fff;
                border-radius: 44px;
                width: fit-content;
                box-shadow: 0px 12px 18px 3px #0EB7FF;
                backdrop-filter: blur(3px);
                border-radius: 3vw;
                cursor: pointer;
                padding: 1.2vw 2.5vw;

                @media screen and (max-width: 991px) {
                    padding: 15px 40px;
                    border-radius: 50px;
                }

                @media screen and (max-width: 667px) {
                    padding: 10px 40px;
                    border-radius: 50px;
                }

                @media screen and (max-width: 300px) {
                    padding: 10px 30px;
                    border-radius: 50px;
                }

                span {
                    text-align: center;
                    font-family: 'Microsoft YaHei';
                    font-size: 0.9vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    background: linear-gradient(66deg, #1A1D57 0%, #2363C3 46.15%, #0EB7FF 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width:991px) {
                        font-size: 17px;
                    }

                    @media screen and (max-width:667px) {
                        font-size: 12px;
                    }
                }

                &:hover {
                    background: var(--linear, linear-gradient(66deg, #1A1D57 0%, #0EB7FF 46.15%, #2363C3 100%));
    
                    span {
                        background: white;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}